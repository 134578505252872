@import 'assets/styles/mixins.scss';

.form {
  max-width: 70rem;

  .form_input{
    border-radius: 8px;
    &_error {
      @extend .form_input;
      border-color: $danger;
    }
  }

  .error_message_container {
    margin-top: 12px;
  }

  &_loading {
    pointer-events: none;
    .form_input {
      border-radius: 8px;
    }
  }

  .form_item {
    &_with_error {
      padding-bottom: 0 !important;
      margin-bottom: 0;

      .stripe_error {
        color: $danger;
        font-size: 1.2rem;
        margin: 0;
      }
    }
  }

  .form_actions {
    margin: 0;
  }
}

/** Animations **/
@keyframes showMessage {
  from {
    opacity: 0;
    height: 0;
  }

  to {
    opacity: 1;
    height: 20px;
  }
}

@keyframes reduceMargin {
  from {
    margin-bottom: 25px;
  }

  to {
    margin-bottom: 0;
  }
}

.cancel_button {
  margin-top: 8px;
}

.form_item {
  margin-bottom: 0;
}

.card_input_container {
  position: relative;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.alert_stripe_card {
  margin-bottom: 8px;
}
