@import 'assets/styles/mixins.scss';

.progress_container {
  width: 100%;

  :global {
    .ant-progress-inner {
      background-color: $gray-300;
    }
  }

  &.default_bar {
    :global {
      .ant-progress-bg {
        height: 8px !important;
      }
    }
  }

  &.small_bar {
    :global {
      .ant-progress-bg {
        height: 4px !important;
      }
    }
  }
}

.progress_title_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;

  span {
    font-size: 16px;
    line-height: 19px;
    font-style: normal;
    color: $purple;
    flex-shrink: 0;
  }
}

.progress_subtitle {
  font-size: 14px;
  line-height: 16px;
  color: $text-span;
  font-style: italic;
}
