@import 'assets/styles/mixins.scss';

.progress_container {
  width: 100%;

  :global {
    .ant-progress-inner {
      background-color: $gray-300;
    }
  }

  &.default_bar {
    :global {
      .ant-progress-bg {
        height: 8px !important;
        background-color: rgba($purple,0.6);
      }
      .ant-progress-success-bg {
        height: 8px !important;
        background-color: $purple-dark;
      }
    }
  }

  &.small_bar {
    :global {
      .ant-progress-bg {
        height: 4px !important;
        background-color: rgba($purple,0.6);

      }
      .ant-progress-success-bg {
        height: 4px !important;
        background-color: $purple-dark;
      }
    }
  }
}

.progress_title_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;

  span {
    font-size: 16px;
    line-height: 19px;
    font-style: normal;
    color: $purple;
    flex-shrink: 0;
  }
}

.progress_subtitle {
  font-size: 14px;
  line-height: 16px;
  color: $text-span;
  font-style: italic;
}

.progress_wrapper {
  position: relative;
  .primary_progress {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 2;
  }
  .secondary_progress {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
  }
}

.message_wrapper {
  margin-top: 16px;
}
