@import 'assets/styles/mixins.scss';

// ANT DESIGN POPOVER

.ant-popover {
  &-inner-content {
    padding: 0;
    border-radius: 3px;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.3);

    .ant-popover-message {
      padding: 20px;

      &-title {
        padding: 0;
      }
    }
    .ant-popover-buttons {
      width: 100%;
      display: flex;
      margin-bottom: 0;

      button {
        flex: 1;
        margin: 0;
        height: 35px;
        border-radius: 0;
        border: none;

        &:first-child {
          border-bottom-left-radius: 3px;
        }

        &:last-child {
          border-bottom-right-radius: 3px;
        }
      }
    }
  }
}
