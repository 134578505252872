@import 'assets/styles/mixins.scss';

.project_info_header {
  margin-right: auto;
  line-height: 1;
  margin: 0 auto 0 48px;
  .project_name {
    color: $text;
    font-family: $base__font-family;
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
  }

  .project_url {
    color: $purple-dark;
    font-size: 1.4rem;

    &:hover {
      text-decoration: underline;
    }
  }
}