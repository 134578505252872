@import 'assets/styles/mixins.scss';

.languages_container {
  margin-top: 32px;

  & .languages {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 16px;
  }
}

.languages_container h1 {
  font-size: 16px;
  font-weight: bold;
  font-family: $title__font-family;
  line-height: 19px;
  color: $gray-700;
}

.languages_container p {
  font-size: 16px;
  line-height: 19px;
  font-family: $base__font-family;
  color: $purple;
}

.free_first_language {
  color: $purple-dark;
  display: inline-block;
  font-weight: bold;
}

.m_t60 {
  margin-top: 60px;
}

.modal_button {
  flex: 1;
}

.footer {
  margin-top: 40px;
}

.container_resume {
  background-color: $gray-50;
  padding: 32px;
  border-radius: 8px;
  margin-top: 0;
  margin-bottom: 40px;
}
