@import 'assets/styles/mixins.scss';
//TODO: isNecessary?

.ant_modal {
  width: 620px !important;
  top: 65px;
}

.credit_card {
  .modal_title {
    font-family: $title__font-family;
    font-size: 3rem;
    margin-top: 10px;
    margin-bottom: 40px;

    &.no_card {
      margin-bottom: 0 !important;
    }
  }

  &_subtitle {
    font-size: 1.5rem;
    margin-top: 25px;
    // margin-bottom: -10px;
  }
}

.billing_data {
  &_subtitle {
    font-size: 1.5rem;
    margin-top: 35px;
  }
}

.payment_btn {
  width: 100%;
  margin-top: 50px;
  font-size: 1.6rem;
  height: 70px !important;
}

.payment_credits {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;

  img {
    margin-bottom: 0;
    height: 20px;

    &:hover {
      color: $primary;
    }
  }
}

%icon {
  font-size: 3.4rem;
  margin: 25px 0;
}

%title {
  margin-bottom: 25px;
}

.success_container {
  text-align: center;

  .icon_success,
  .icon_fail {
    @extend %icon;
  }

  .icon_success {
    color: $success;
  }
  .icon_fail {
    color: $danger;
  }

  .title_success,
  .title_fail {
    @extend %title;
  }

  .title_success {
    color: $success;
  }
  .title_fail {
    color: $danger;
  }
}

.hidden {
  display: none;
}
