@import 'assets/styles/mixins.scss';

.tab {
  padding: 15px 16px 18px 16px !important;
  margin-right: 8px;

  &:hover, &:active, &:focus {
    color: $purple-dark;
    background-color: rgba($purple-dark, .05);
  }

  &_active {
    @extend .tab;

    &:hover, &:active, &:focus {
      background-color: $purple-dark;
      color: $white;
    }
  }
}
