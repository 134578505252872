@import 'assets/styles/mixins.scss';

.summary_content {
  width: 100%;
  border-radius: 8px;
  background-color: $gray-100;
  padding: 24px;
  & > div .low_span {
    font-style: italic;
    font-size: 14px;
  }

  & > div .title_span {
    font-style: normal;
    font-size: 16px;
    color: $purple;
  }
  & button {
    margin-top: 32px;
  }
}

.summary_title {
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  margin: 0;
  color: $black-dark;
  font-family: $title__font-family;
}

.summary_total {
  @extend .summary_title;
  font-family: $base__font-family;
}

.summary_subtitle {
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  margin: 16px 0 0 0;
  color: $black-dark;
}

.summary_extra_info_subtitle {
  @extend .summary_subtitle;
  margin: 16px 0 12px 0;
}

.summary_info {
  margin-bottom: 12px;
  padding: 12px 0;
  border: 1px solid $gray;
  border-left: 0;
  border-right: 0;
  span {
    color: $text-span
  }
}

.summary_field_container {
  display: flex;
  flex-direction: column;

  .summary_field_subtotal_container {
    padding: 12px 0;
    border: 1px solid $gray;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;

    .summary_field_subtotal {
      display: flex;
      justify-content: space-between;
    }

    .summary_field_subtotal_field span {
      font-size: 16px;
      font-style: normal;
    }
    .summary_field_subtotal_field .subtotal_text {
      color: $black-dark;
    }
  }

  & .field {
    display: flex;
    justify-content: space-between;
  }
}
.summary_info_title {
  margin-top: 16px;
}
.summary_info_property {
  display: flex;
  justify-content: space-between;
  .summary_extra_info {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  p {
    margin: 0;
  }
}

.info_message {
  margin: 16px 0 0 0;
  color: $danger-dark;
  font-size: 16px;
  line-height: 19px;
}

.fields_container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 12px 0;
}

.field_link {
  color: $purple-dark;
}

.isBlue {
  color: $purple-dark !important;
}

.payment_error {
  margin-top: 32px;
}

.line_through {
  text-decoration: line-through;
}
