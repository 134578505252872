@import "assets/styles/mixins.scss";

.privacy_check {
  font-size: 1.5rem;
  border-radius: 8px;
  background-color: $gray-50;
  padding: 16px;
 
  label::after {
    content: '' !important;
  }
  label {
    display: flex;
    gap: 18px;
    align-items: flex-start;
    span {
      line-height: 1.3;
      padding: 0;
    }
    :global(.ant-checkbox-inner) {
      border-width: 2px;
      border-color: $black;
    }
    :global(.ant-checkbox) {
      margin-top: 0.36em;
    }
   
  }

}

.consultant_form_content  {

  >div {
    display: flex;
    justify-content: center;
  }
  
  .left_content {
    max-width: 70ch;
    .left_content_image{
      display: none;
      margin: auto;
      @media screen and (min-width: $xl-min-width) {
        display: block;
      }
    }
  }

  h2 {
    font-family: $title__font-family;
    margin-bottom: 12px;
    font-size: 24px;
    font-weight: bold;
    max-width: 80ch;
  }

  p {
    color: $purple;
    font-size: 16px;
    margin-bottom: 40px;
  }

  .consultant_form {
    border: 1px solid $gray-100;
    border-radius: 8px;
    padding: 32px;
    flex: 1;
    h3 {
      font-family: $title__font-family;
      font-weight: bold;
    }
    .select_label {
      font-family: $base__font-family;
      color: $gray-700;
      font-weight: bold;
      font-size: 16px;
      line-height: 1;
      margin-bottom: 12px;
      
    }
    .select_country, .select_language {
      input, div:last-child {
        border-radius: 8px;
      }
      
      :global(.ant-select-selection__placeholder)  {
        position: absolute;
        overflow: visible;
      }

    }
    @media screen and (min-width: $xl-min-width) {
      max-width: 41rem;
    }
    
    button {
      border-radius: 8px !important;
    }

    .form_input {
    
      input {
        border-radius: 8px;
      }
      margin-top: 24px;
    }
    .form_input_phone {
      margin-top: 24px;
    }
  }
}

.inner {
  max-width: $xl-min-width;
  width: 100%;
  margin: 0 auto;
}