@import 'assets/styles/mixins.scss';

.common_overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: white;
  padding: 32px;
  border-radius: 8px;
  max-width: 520px;
  width: 100%;
}

.rightColumn {
  flex: 0;
  display: none;
  position: relative;

  &.isFade {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($black, 0.5);
    }
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (min-width: 1024px) {
    flex: 2.2;
    display: block;
  }
}

.banner_overlay {
  @extend .common_overlay;
  background-color: transparent;
  max-width: calc(520px + 64px);
}
