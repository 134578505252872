@import 'assets/styles/mixins.scss';

.help_accordion_wrapper {
  background-color: $gray-50;
  padding: 32px;
  border-radius: 8px;

  .help_accordion_header {
    display: flex;
    gap: 16px;
    align-items: center;
    cursor: pointer;

    &_icon {
      color: $purple-dark
    }

    &_text {
      flex: 1;

      p, h4 {
        color: $text-span;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        margin: 0;
      }

      h4 {
        font-weight: bold;
      }
    }
  }

  .help_accordion_body {
    margin-top: 24px;
  }
}
