@import 'assets/styles/mixins.scss';

.question_container {
    margin-top: 32px;
}
.description {
    font-size: 14px;
    line-height: 16px;
    color: $gray-700;
}

.footer {
  margin-top: 40px;
}
