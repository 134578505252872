@import 'assets/styles/mixins.scss';

.dropdown {
  font-size: 1.2rem;
  padding-right: 15px;
  position: relative;
  cursor: pointer;
  @include transition-slow;

  &::after {
    color: $gray;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -2px;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    @include transition-slow();
  }

  &:hover {
    color: $primary;

    &::after {
      color: $primary;
    }
  }

  :global {
    .anticon {
      font-size: rem(16) !important;
    }
  }
}
