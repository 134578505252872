// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN NOTIFICATION */

.ant-notification {
  max-width: calc(100vw - 48px);
  top: 100px;

  &.ant-notification-topRight {
    top: 75px !important;
    right: -14px !important;
  }
}

.notification-no-closable {
  .ant-notification-notice-close {
    display: none;
  }
}
