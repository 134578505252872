@import 'assets/styles/mixins.scss';

.welcome_header {
  background: $white;
  padding: 0 rem(20);
  padding-right: 20px;
  min-height: 80px;
  height: $top_bar-height;
  border-bottom: 1px solid $gray-border;
  color: $text;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  margin: 0!important;
  .logo_wrapper {
    max-width: 94px;
    display: block;
    flex: 1;
    img {
      max-width: 100%;
    }
  }
}
