// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN MENU */

.ant-menu {
  background: $menuBg;

  .ant-menu-item {
    outline: none !important;
    font-weight: 500;
  }

  &:not(.ant-menu-horizontal) {
    .ant-menu-item-selected {
      background-color: #f8f8f8;

      &::after {
        content: none;
      }
    }
  }

  .ant-menu-submenu > .ant-menu {
    background-color: $menuBg;

    .ant-menu-item {
      padding-left: 70px !important;
    }
  }

  .ant-menu-submenu.ant-menu-submenu-inline {
    .ant-menu-submenu-title {
      margin: 0;
      // height: 50px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      & > span {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
      }

      .ant-menu-submenu-arrow {
        position: initial;
        margin-left: auto;
      }
    }
  }
}

:global(.ant-menu-inline-collapsed > .ant-menu-item) {
  padding-left: 26px !important;
  margin: 0;
}

.ant-divider-horizontal.ant-divider-with-text-left {
  font-size: 1.05rem;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $gray-menu;
  margin: 5px 0;
  margin-top: 18px;
  padding-left: 38px;

  &::before,
  &::after {
    opacity: 0;
  }
}

.ant-menu-inline .ant-menu-item {
  margin: 0;
  display: flex;
  align-items: center;
}

.ant-menu-inline > .ant-menu-item {
  // height: 50px;
  font-weight: 500;
}

.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}

.ant-menu-dark.ant-menu-submenu-popup {
  .ant-menu-item-divider {
    opacity: 0;
  }
}
