@import 'assets/styles/mixins.scss';

.methods_container {
  max-width: 60rem;

  .selection_btns {
    display: flex;
    margin-bottom: 10px;

    .method_btn {
      height: 35px;
    }
  }

  .cancel_btn {
    margin-top: 24px;
  }

  .form_container {
    padding: 25px;
    border-radius: 5px;
    border: 1px solid rgba($gray, 0.5);
    box-shadow: 0 4px 6px 0 rgba($gray, 0.5);

    &_checkout {
      padding: 0;
      border-radius: 0;
      border: none;
      box-shadow: none;
    }

    .select_method {
      margin: 0;
      font-size: 1.4rem;
    }
  }
}
