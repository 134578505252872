@charset "UTF-8";
@import url(~bootstrap-css-only/css/bootstrap.min.css);
@import url(~c3/c3.min.css);
body {
  /*  ANT DESIGN BACKTOP */
  /*  ANT DESIGN BADGE */
  /*  ANT DESIGN CALENDAR */
  /*  ANT DESIGN CASCADER */
  /*  ANT DESIGN DROPDOWN */
  /*  ANT DESIGN FORM */
  /*  ANT DESIGN INPUT */
  /*  ANT DESIGN MENU */
  /*  ANT DESIGN MODAL */
  /*  ANT DESIGN SELECT */
  /*  ANT DESIGN SLIDER */
  /*  ANT DESIGN SPIN */
  /*  ANT DESIGN SELECT */
  /*  ANT DESIGN LAYOUT */
  /*  ANT DESIGN NOTIFICATION */
  /*  ANT DESIGN PROGRESS */ }
  @media screen and (max-width: 767px) {
    body html {
      font-size: 10px; } }
  @media screen and (max-width: 575px) {
    body html {
      font-size: 9px; } }
  @media screen and (min-width: 2000px) {
    body html {
      font-size: 11px; } }
  body :export {
    linearToColor: #4738c3;
    linearFromColor: #d71658;
    purpleDark: #1260E6;
    warningColor: #FE7F16;
    successColor: #46be8a;
    errorColor: #e1176b;
    disabled: #9e9e9e;
    collapseDelay: 250ms; }
  body .ant-alert {
    font-size: 1.4rem;
    line-height: 1.6;
    border: none;
    padding: 15px 30px 15px 40px; }
    body .ant-alert a {
      color: inherit;
      font-weight: 600;
      text-decoration: underline; }
    body .ant-alert.ant-alert-no-icon {
      padding: 15px 30px; }
    body .ant-alert .ant-alert-icon {
      top: 17.75px; }
    body .ant-alert .ant-alert-message {
      margin-bottom: 0; }
      body .ant-alert .ant-alert-message * {
        margin-bottom: 0; }
    body .ant-alert .ant-alert-description {
      margin: 0 !important; }
  body .ant-alert-success .ant-alert-message {
    color: var(--green); }
  body .ant-alert-info .ant-alert-message {
    color: var(--blue); }
  body .ant-alert-warning .ant-alert-message {
    color: var(--orange); }
  body .ant-alert-error .ant-alert-message {
    color: var(--red); }
  body .ant-alert-close-icon {
    top: 15px; }
  @media screen and (max-width: 767px) {
    body html {
      font-size: 10px; } }
  @media screen and (max-width: 575px) {
    body html {
      font-size: 9px; } }
  @media screen and (min-width: 2000px) {
    body html {
      font-size: 11px; } }
  body :export {
    linearToColor: #4738c3;
    linearFromColor: #d71658;
    purpleDark: #1260E6;
    warningColor: #FE7F16;
    successColor: #46be8a;
    errorColor: #e1176b;
    disabled: #9e9e9e;
    collapseDelay: 250ms; }
  body .ant-back-top {
    right: 4.16rem;
    bottom: 4.16rem; }
  body .ant-back-top-content {
    border-radius: 5px;
    background: #d2d9e5; }
    body .ant-back-top-content:hover {
      background: #b8beca; }
  @media screen and (max-width: 767px) {
    body html {
      font-size: 10px; } }
  @media screen and (max-width: 575px) {
    body html {
      font-size: 9px; } }
  @media screen and (min-width: 2000px) {
    body html {
      font-size: 11px; } }
  body :export {
    linearToColor: #4738c3;
    linearFromColor: #d71658;
    purpleDark: #1260E6;
    warningColor: #FE7F16;
    successColor: #46be8a;
    errorColor: #e1176b;
    disabled: #9e9e9e;
    collapseDelay: 250ms; }
  body .ant-badge-count {
    font-family: inherit; }
  @media screen and (max-width: 767px) {
    body html {
      font-size: 10px; } }
  @media screen and (max-width: 575px) {
    body html {
      font-size: 9px; } }
  @media screen and (min-width: 2000px) {
    body html {
      font-size: 11px; } }
  body :export {
    linearToColor: #4738c3;
    linearFromColor: #d71658;
    purpleDark: #1260E6;
    warningColor: #FE7F16;
    successColor: #46be8a;
    errorColor: #e1176b;
    disabled: #9e9e9e;
    collapseDelay: 250ms; }
  body .ant-btn {
    border-radius: 8px;
    height: 50px;
    font-weight: 500;
    background-color: #e8e8e8;
    color: #8f8f8f;
    padding: 0 45px;
    font-size: 1.5rem; }
    body .ant-btn:hover {
      border-color: transparent; }
    body .ant-btn:not(.ant-btn-primary):not(.ant-btn-danger):not(.ant-btn-dashed):not(.ant-btn-link):hover, body .ant-btn:not(.ant-btn-primary):not(.ant-btn-danger):not(.ant-btn-dashed):not(.ant-btn-link):focus, body .ant-btn:not(.ant-btn-primary):not(.ant-btn-danger):not(.ant-btn-dashed):not(.ant-btn-link):active {
      background-color: rgba(210, 217, 229, 0.7); }
    body .ant-btn[disabled], body .ant-btn[disabled]:hover {
      color: #fff;
      background-color: rgba(210, 217, 229, 0.7); }
    body .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
      padding-left: 45px; }
  body .ant-btn-primary {
    background-color: #4b58dd;
    color: white; }
  body .ant-btn-danger {
    background-color: #DD3444;
    color: white; }
  body .ant-btn-dashed  {
    background-color: #fff; }
  body .ant-btn-link {
    background-color: #fff;
    color: #4b58dd;
    padding: 0; }
  @media screen and (max-width: 767px) {
    body html {
      font-size: 10px; } }
  @media screen and (max-width: 575px) {
    body html {
      font-size: 9px; } }
  @media screen and (min-width: 2000px) {
    body html {
      font-size: 11px; } }
  body :export {
    linearToColor: #4738c3;
    linearFromColor: #d71658;
    purpleDark: #1260E6;
    warningColor: #FE7F16;
    successColor: #46be8a;
    errorColor: #e1176b;
    disabled: #9e9e9e;
    collapseDelay: 250ms; }
  body .ant-calendar {
    border: 1px solid #e9e9e9; }
  body .events {
    list-style: none;
    margin: 0;
    padding: 0; }
  body .events .ant-badge-status {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 12px; }
  body .notes-month {
    text-align: center;
    font-size: 28px; }
  body .notes-month section {
    font-size: 28px; }
  body .ant-fullcalendar-month-select {
    margin-left: 5px; }
  body .ant-calendar-picker-container {
    animation-duration: 0s !important; }
  body .ant-calendar-range .ant-calendar-input-wrap {
    height: 38px; }
  body .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: #4b58dd; }
  @media screen and (max-width: 767px) {
    body html {
      font-size: 10px; } }
  @media screen and (max-width: 575px) {
    body html {
      font-size: 9px; } }
  @media screen and (min-width: 2000px) {
    body html {
      font-size: 11px; } }
  body :export {
    linearToColor: #4738c3;
    linearFromColor: #d71658;
    purpleDark: #1260E6;
    warningColor: #FE7F16;
    successColor: #46be8a;
    errorColor: #e1176b;
    disabled: #9e9e9e;
    collapseDelay: 250ms; }
  body .ant-cascader-menus {
    border: 1px solid #e9e9e9; }
  @media screen and (max-width: 767px) {
    body html {
      font-size: 10px; } }
  @media screen and (max-width: 575px) {
    body html {
      font-size: 9px; } }
  @media screen and (min-width: 2000px) {
    body html {
      font-size: 11px; } }
  body :export {
    linearToColor: #4738c3;
    linearFromColor: #d71658;
    purpleDark: #1260E6;
    warningColor: #FE7F16;
    successColor: #46be8a;
    errorColor: #e1176b;
    disabled: #9e9e9e;
    collapseDelay: 250ms; }
  body .ant-dropdown-menu {
    border: 1px solid #e9e9e9;
    padding: 0; }
  body .ant-dropdown-menu-item-divider {
    margin: 0; }
  body .ant-dropdown-menu-item,
  body .ant-dropdown-menu-submenu-title {
    font-size: 1.16rem;
    padding: 1rem 1.66rem; }
  body .ant-dropdown-menu-item > a,
  body .ant-dropdown-menu-submenu-title > a {
    padding: 0.66rem 1.66rem;
    margin: -0.67rem -1.67rem; }
  body .ant-dropdown-menu-item > a.btn,
  body .ant-dropdown-menu-submenu-title > a.btn {
    margin: -0.67rem 0; }
  body .ant-dropdown-menu-item-group-list {
    list-style: none;
    padding: 0; }
  body .ant-dropdown-menu-item-group-title {
    font-weight: bold;
    font-size: 1.08rem;
    padding: 1rem 1.66rem;
    color: #74708d; }
  @media screen and (max-width: 767px) {
    body html {
      font-size: 10px; } }
  @media screen and (max-width: 575px) {
    body html {
      font-size: 9px; } }
  @media screen and (min-width: 2000px) {
    body html {
      font-size: 11px; } }
  body :export {
    linearToColor: #4738c3;
    linearFromColor: #d71658;
    purpleDark: #1260E6;
    warningColor: #FE7F16;
    successColor: #46be8a;
    errorColor: #e1176b;
    disabled: #9e9e9e;
    collapseDelay: 250ms; }
  body .ant-row.ant-form-item {
    padding-bottom: 5px; }
  body .ant-form input[type='file'] {
    min-height: 40px; }
  body .ant-form input.ant-input-sm[type='file'] {
    min-height: 48px; }
  body .ant-form input.ant-input-lg[type='file'] {
    min-height: 52px; }
  body .ant-form-item-label {
    text-align: left;
    white-space: normal; }
  body .ant-form-item > .ant-form-item,
  body .ant-form-item :not(.ant-form) > .ant-form-item {
    margin-top: -2px; }
  body form .ant-select:only-child,
  body form .ant-cascader-picker:only-child {
    display: inline-block; }
  @media screen and (max-width: 767px) {
    body html {
      font-size: 10px; } }
  @media screen and (max-width: 575px) {
    body html {
      font-size: 9px; } }
  @media screen and (min-width: 2000px) {
    body html {
      font-size: 11px; } }
  body :export {
    linearToColor: #4738c3;
    linearFromColor: #d71658;
    purpleDark: #1260E6;
    warningColor: #FE7F16;
    successColor: #46be8a;
    errorColor: #e1176b;
    disabled: #9e9e9e;
    collapseDelay: 250ms; }
  body .ant-input-disabled {
    background: #f9fafc; }
  body .ant-input, body .ant-input-affix-wrapper {
    transition: border 300ms ease-in-out;
    height: 50px;
    border-width: 1.5px;
    border-radius: 8px;
    font-size: 1.4rem; }
  body .ant-input:hover {
    border-width: 1.5px;
    border-right-width: 1.5px !important;
    border-color: #4b58dd; }
  body .ant-input:focus {
    border-width: 1.5px;
    border-right-width: 1.5px !important; }
  body .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #4b58dd;
    border-right-width: 1.5px !important; }
  body .ant-input-number {
    height: 50px;
    border-width: 1.5px;
    border-radius: 2px; }
    body .ant-input-number .ant-input-number-input-wrap {
      height: 50px;
      display: flex;
      align-items: center; }
    body .ant-input-number:hover, body .ant-input-number.ant-input-number-focused {
      border-width: 1.5px;
      border-right-width: 1.5px !important;
      border-color: #4b58dd; }
    body .ant-input-number:hover .ant-input-number-handler-wrap {
      opacity: 0;
      display: none; }
  body .has-error .ant-input:focus,
  body .has-error .ant-input:hover,
  body .has-error .ant-input:not([disabled]):hover {
    border-width: 1.5px;
    border-right-width: 1.5px !important;
    border-color: #ea366d; }
  body .has-error .ant-form-explain,
  body .has-error .ant-form-split {
    margin-top: 5px; }
  @media screen and (max-width: 767px) {
    body html {
      font-size: 10px; } }
  @media screen and (max-width: 575px) {
    body html {
      font-size: 9px; } }
  @media screen and (min-width: 2000px) {
    body html {
      font-size: 11px; } }
  body :export {
    linearToColor: #4738c3;
    linearFromColor: #d71658;
    purpleDark: #1260E6;
    warningColor: #FE7F16;
    successColor: #46be8a;
    errorColor: #e1176b;
    disabled: #9e9e9e;
    collapseDelay: 250ms; }
  body .ant-form label {
    font-size: 1.4rem;
    font-weight: 500; }
    body .ant-form label::after {
      content: ''; }
  @media screen and (max-width: 767px) {
    body html {
      font-size: 10px; } }
  @media screen and (max-width: 575px) {
    body html {
      font-size: 9px; } }
  @media screen and (min-width: 2000px) {
    body html {
      font-size: 11px; } }
  body :export {
    linearToColor: #4738c3;
    linearFromColor: #d71658;
    purpleDark: #1260E6;
    warningColor: #FE7F16;
    successColor: #46be8a;
    errorColor: #e1176b;
    disabled: #9e9e9e;
    collapseDelay: 250ms; }
  body .ant-menu {
    background: #edeff3; }
    body .ant-menu .ant-menu-item {
      outline: none !important;
      font-weight: 500; }
    body .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #f8f8f8; }
      body .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected::after {
        content: none; }
    body .ant-menu .ant-menu-submenu > .ant-menu {
      background-color: #edeff3; }
      body .ant-menu .ant-menu-submenu > .ant-menu .ant-menu-item {
        padding-left: 70px !important; }
    body .ant-menu .ant-menu-submenu.ant-menu-submenu-inline .ant-menu-submenu-title {
      margin: 0;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: flex-start; }
      body .ant-menu .ant-menu-submenu.ant-menu-submenu-inline .ant-menu-submenu-title > span {
        display: flex;
        align-items: center;
        flex-direction: row-reverse; }
      body .ant-menu .ant-menu-submenu.ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow {
        position: initial;
        margin-left: auto; }
  body :global(.ant-menu-inline-collapsed > .ant-menu-item) {
    padding-left: 26px !important;
    margin: 0; }
  body .ant-divider-horizontal.ant-divider-with-text-left {
    font-size: 1.05rem;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #99a9bd;
    margin: 5px 0;
    margin-top: 18px;
    padding-left: 38px; }
    body .ant-divider-horizontal.ant-divider-with-text-left::before, body .ant-divider-horizontal.ant-divider-with-text-left::after {
      opacity: 0; }
  body .ant-menu-inline .ant-menu-item {
    margin: 0;
    display: flex;
    align-items: center; }
  body .ant-menu-inline > .ant-menu-item {
    font-weight: 500; }
  body .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 0; }
  body .ant-menu-dark.ant-menu-submenu-popup .ant-menu-item-divider {
    opacity: 0; }
  @media screen and (max-width: 767px) {
    body html {
      font-size: 10px; } }
  @media screen and (max-width: 575px) {
    body html {
      font-size: 9px; } }
  @media screen and (min-width: 2000px) {
    body html {
      font-size: 11px; } }
  body :export {
    linearToColor: #4738c3;
    linearFromColor: #d71658;
    purpleDark: #1260E6;
    warningColor: #FE7F16;
    successColor: #46be8a;
    errorColor: #e1176b;
    disabled: #9e9e9e;
    collapseDelay: 250ms; }
  body .ant-modal-close {
    outline: none !important; }
  body .ant-modal-confirm.ant-modal-confirm-confirm .ant-modal-body {
    padding: 0;
    padding-top: 32px; }
    body .ant-modal-confirm.ant-modal-confirm-confirm .ant-modal-body i {
      display: none; }
  body .ant-modal-confirm.ant-modal-confirm-confirm .ant-modal-confirm-body {
    padding: 12px 24px;
    text-align: center; }
    body .ant-modal-confirm.ant-modal-confirm-confirm .ant-modal-confirm-body .ant-modal-confirm-content {
      margin-left: 0; }
  body .ant-modal-confirm.ant-modal-confirm-confirm .ant-modal-confirm-btns {
    float: none;
    width: 100%;
    display: flex; }
    body .ant-modal-confirm.ant-modal-confirm-confirm .ant-modal-confirm-btns button {
      flex: 1;
      margin: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
      body .ant-modal-confirm.ant-modal-confirm-confirm .ant-modal-confirm-btns button:not(.ant-btn-primary) {
        border-bottom-right-radius: 0; }
      body .ant-modal-confirm.ant-modal-confirm-confirm .ant-modal-confirm-btns button.ant-btn-primary {
        border-bottom-left-radius: 0; }
  body .ant-modal.welcome-modal {
    width: 600px !important; }
    body .ant-modal.welcome-modal .ant-modal-content .ant-modal-header {
      border-bottom: 0; }
      body .ant-modal.welcome-modal .ant-modal-content .ant-modal-header .ant-modal-title {
        font-size: 2rem; }
    body .ant-modal.welcome-modal .ant-modal-content .ant-modal-body {
      font-size: 1.4rem; }
    body .ant-modal.welcome-modal .ant-modal-content .ant-modal-footer {
      border-top: 0; }
  @media screen and (max-width: 767px) {
    body html {
      font-size: 10px; } }
  @media screen and (max-width: 575px) {
    body html {
      font-size: 9px; } }
  @media screen and (min-width: 2000px) {
    body html {
      font-size: 11px; } }
  body :export {
    linearToColor: #4738c3;
    linearFromColor: #d71658;
    purpleDark: #1260E6;
    warningColor: #FE7F16;
    successColor: #46be8a;
    errorColor: #e1176b;
    disabled: #9e9e9e;
    collapseDelay: 250ms; }
  body .ant-popover-inner-content {
    padding: 0;
    border-radius: 3px;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.3); }
    body .ant-popover-inner-content .ant-popover-message {
      padding: 20px; }
      body .ant-popover-inner-content .ant-popover-message-title {
        padding: 0; }
    body .ant-popover-inner-content .ant-popover-buttons {
      width: 100%;
      display: flex;
      margin-bottom: 0; }
      body .ant-popover-inner-content .ant-popover-buttons button {
        flex: 1;
        margin: 0;
        height: 35px;
        border-radius: 0;
        border: none; }
        body .ant-popover-inner-content .ant-popover-buttons button:first-child {
          border-bottom-left-radius: 3px; }
        body .ant-popover-inner-content .ant-popover-buttons button:last-child {
          border-bottom-right-radius: 3px; }
  @media screen and (max-width: 767px) {
    body html {
      font-size: 10px; } }
  @media screen and (max-width: 575px) {
    body html {
      font-size: 9px; } }
  @media screen and (min-width: 2000px) {
    body html {
      font-size: 11px; } }
  body :export {
    linearToColor: #4738c3;
    linearFromColor: #d71658;
    purpleDark: #1260E6;
    warningColor: #FE7F16;
    successColor: #46be8a;
    errorColor: #e1176b;
    disabled: #9e9e9e;
    collapseDelay: 250ms; }
  body .ant-radio-group-large .ant-radio-button-wrapper {
    white-space: nowrap;
    height: 49px;
    margin: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    border-width: 1.5px;
    border-left: 1.5px solid transparent;
    border-color: #d2d9e5;
    box-shadow: none; }
    body .ant-radio-group-large .ant-radio-button-wrapper::before {
      content: none; }
    body .ant-radio-group-large .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-color: #4b58dd; }
    body .ant-radio-group-large .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
      box-shadow: none; }
    body .ant-radio-group-large .ant-radio-button-wrapper:first-child {
      margin-left: 0; }
    body .ant-radio-group-large .ant-radio-button-wrapper:last-child {
      margin-right: 0; }
  @media screen and (max-width: 767px) {
    body html {
      font-size: 10px; } }
  @media screen and (max-width: 575px) {
    body html {
      font-size: 9px; } }
  @media screen and (min-width: 2000px) {
    body html {
      font-size: 11px; } }
  body :export {
    linearToColor: #4738c3;
    linearFromColor: #d71658;
    purpleDark: #1260E6;
    warningColor: #FE7F16;
    successColor: #46be8a;
    errorColor: #e1176b;
    disabled: #9e9e9e;
    collapseDelay: 250ms; }
  body .ant-select {
    font-family: 'Work Sans', sans-serif;
    font-size: 1.3rem; }
    body .ant-select .ant-select-selection--single {
      height: 50px;
      border-radius: 8px;
      border-width: 1.5px !important; }
    body .ant-select .ant-select-selection__rendered {
      margin-left: 18px;
      line-height: 47px; }
    body .ant-select .ant-select-selection__placeholder,
    body .ant-select .ant-select-search__field__placeholder {
      margin-right: 20px; }
    body .ant-select:not(.ant-select-disabled) .ant-select-selection:hover {
      border-color: #4b58dd; }
    body .ant-select .ant-select-arrow {
      font-size: 11px;
      transform: scaleY(0.8); }
  body .ant-select-dropdown {
    border: 1px solid #e9e9e9;
    animation-duration: 0s !important; }
  body .ant-select-auto-complete ol,
  body .ant-select-auto-complete ul,
  body .ant-select-auto-complete dl {
    margin: 0; }
  @media screen and (max-width: 767px) {
    body html {
      font-size: 10px; } }
  @media screen and (max-width: 575px) {
    body html {
      font-size: 9px; } }
  @media screen and (min-width: 2000px) {
    body html {
      font-size: 11px; } }
  body :export {
    linearToColor: #4738c3;
    linearFromColor: #d71658;
    purpleDark: #1260E6;
    warningColor: #FE7F16;
    successColor: #46be8a;
    errorColor: #e1176b;
    disabled: #9e9e9e;
    collapseDelay: 250ms; }
  body .ant-slider-with-marks {
    margin-bottom: 19px; }
  @media screen and (max-width: 767px) {
    body html {
      font-size: 10px; } }
  @media screen and (max-width: 575px) {
    body html {
      font-size: 9px; } }
  @media screen and (min-width: 2000px) {
    body html {
      font-size: 11px; } }
  body :export {
    linearToColor: #4738c3;
    linearFromColor: #d71658;
    purpleDark: #1260E6;
    warningColor: #FE7F16;
    successColor: #46be8a;
    errorColor: #e1176b;
    disabled: #9e9e9e;
    collapseDelay: 250ms; }
  body .ant-spin-blur {
    filter: none !important; }
  body .ant-spin-nested-loading > div > .ant-spin {
    max-height: none; }
  @media screen and (max-width: 767px) {
    body html {
      font-size: 10px; } }
  @media screen and (max-width: 575px) {
    body html {
      font-size: 9px; } }
  @media screen and (min-width: 2000px) {
    body html {
      font-size: 11px; } }
  body :export {
    linearToColor: #4738c3;
    linearFromColor: #d71658;
    purpleDark: #1260E6;
    warningColor: #FE7F16;
    successColor: #46be8a;
    errorColor: #e1176b;
    disabled: #9e9e9e;
    collapseDelay: 250ms; }
  body .ant-switch {
    height: 14px;
    min-width: 38px; }
    body .ant-switch::after {
      left: -5px;
      top: -3px;
      border: 1px solid rgba(210, 217, 229, 0.4);
      box-shadow: 0 2px 4px 0 rgba(116, 112, 141, 0.6); }
  body .ant-switch-small {
    height: 11px;
    min-width: 25px; }
    body .ant-switch-small::after {
      width: 16px;
      height: 16px;
      top: -3px;
      left: -5px; }
  body .ant-switch-checked {
    background-color: rgba(75, 88, 221, 0.4); }
    body .ant-switch-checked::after {
      background-color: #4b58dd;
      left: 110%; }
  body .ant-switch-small.ant-switch-checked {
    background-color: rgba(75, 88, 221, 0.4); }
    body .ant-switch-small.ant-switch-checked::after {
      left: 90%;
      background-color: #4b58dd;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%); }
  @media screen and (max-width: 767px) {
    body html {
      font-size: 10px; } }
  @media screen and (max-width: 575px) {
    body html {
      font-size: 9px; } }
  @media screen and (min-width: 2000px) {
    body html {
      font-size: 11px; } }
  body :export {
    linearToColor: #4738c3;
    linearFromColor: #d71658;
    purpleDark: #1260E6;
    warningColor: #FE7F16;
    successColor: #46be8a;
    errorColor: #e1176b;
    disabled: #9e9e9e;
    collapseDelay: 250ms; }
  body .ant-layout {
    min-height: 100%;
    background: transparent;
    flex: 1; }
  body .ant-layout.ant-layout-has-sider > .ant-layout {
    overflow-x: visible; }
  body .ant-layout-header {
    padding: 0;
    height: 80px; }
  body .ant-layout-footer {
    padding: 0;
    background: transparent; }
  body .ant-collapse {
    background: transparent; }
  body .ant-layout-sider {
    transition: all 0.2s; }
  @media screen and (max-width: 767px) {
    body html {
      font-size: 10px; } }
  @media screen and (max-width: 575px) {
    body html {
      font-size: 9px; } }
  @media screen and (min-width: 2000px) {
    body html {
      font-size: 11px; } }
  body :export {
    linearToColor: #4738c3;
    linearFromColor: #d71658;
    purpleDark: #1260E6;
    warningColor: #FE7F16;
    successColor: #46be8a;
    errorColor: #e1176b;
    disabled: #9e9e9e;
    collapseDelay: 250ms; }
  body .ant-notification {
    max-width: calc(100vw - 48px);
    top: 100px; }
    body .ant-notification.ant-notification-topRight {
      top: 75px !important;
      right: -14px !important; }
  body .notification-no-closable .ant-notification-notice-close {
    display: none; }
  @media screen and (max-width: 767px) {
    body html {
      font-size: 10px; } }
  @media screen and (max-width: 575px) {
    body html {
      font-size: 9px; } }
  @media screen and (min-width: 2000px) {
    body html {
      font-size: 11px; } }
  body :export {
    linearToColor: #4738c3;
    linearFromColor: #d71658;
    purpleDark: #1260E6;
    warningColor: #FE7F16;
    successColor: #46be8a;
    errorColor: #e1176b;
    disabled: #9e9e9e;
    collapseDelay: 250ms; }
  body .ant-progress-text {
    white-space: nowrap; }
  @media screen and (max-width: 767px) {
    body html {
      font-size: 10px; } }
  @media screen and (max-width: 575px) {
    body html {
      font-size: 9px; } }
  @media screen and (min-width: 2000px) {
    body html {
      font-size: 11px; } }
  body :export {
    linearToColor: #4738c3;
    linearFromColor: #d71658;
    purpleDark: #1260E6;
    warningColor: #FE7F16;
    successColor: #46be8a;
    errorColor: #e1176b;
    disabled: #9e9e9e;
    collapseDelay: 250ms; }
  body .ant-table-thead > tr > th {
    background-color: transparent;
    border-bottom-width: 2px; }
  body .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: #fafafa !important; }
  body .ant-table-filter-dropdown {
    margin-left: -20px; }
    body .ant-table-filter-dropdown ul > li {
      padding: 0.8rem; }
    body .ant-table-filter-dropdown .ant-table-filter-dropdown-btns :first-child {
      margin-right: 15px; }
  @media screen and (max-width: 767px) {
    body html {
      font-size: 10px; } }
  @media screen and (max-width: 575px) {
    body html {
      font-size: 9px; } }
  @media screen and (min-width: 2000px) {
    body html {
      font-size: 11px; } }
  body :export {
    linearToColor: #4738c3;
    linearFromColor: #d71658;
    purpleDark: #1260E6;
    warningColor: #FE7F16;
    successColor: #46be8a;
    errorColor: #e1176b;
    disabled: #9e9e9e;
    collapseDelay: 250ms; }
  body .ant-tag {
    border-color: transparent; }

@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

/*  CORE MODULE STYLES */
html {
  font-size: 10px;
  overflow-y: scroll; }
  @media (max-width: 767px) {
    html {
      font-size: 12px; } }

body {
  font-family: "Work Sans", sans-serif !important;
  color: #514d6a;
  line-height: 1.7; }
  @media (max-width: 767px) {
    body {
      font-size: 12px; } }

svg {
  vertical-align: baseline;
  overflow: auto; }

a {
  text-decoration: none;
  color: #1260E6;
  transition: color 0.2s ease-in-out; }
  a:hover, a:active, a:focus {
    color: #0E3158;
    text-decoration: none; }

input {
  outline: none !important;
  font-family: "Work Sans", sans-serif !important;
  color: #514d6a; }

button,
input {
  box-shadow: none !important; }

input[type='text'],
input[type='password'],
input[type='email'],
textarea {
  appearance: none !important; }

input:-webkit-autofill {
  box-shadow: 0 0 0 50px white inset !important; }

label {
  display: inline-block;
  margin-bottom: 0; }

code {
  color: #74708d;
  background: #f2f4f8;
  display: inline-block;
  padding: 0.16rem 0.41rem;
  border-radius: 3px; }

pre {
  color: #74708d;
  padding: 1.66rem;
  border-radius: 3px;
  border: 1px solid #e4e9f0;
  background: #f2f4f8; }

button,
input,
select,
textarea {
  font-family: inherit; }

.ReactCollapse--collapse {
  transition: height 250ms cubic-bezier(0.4, 0, 1, 1); }

@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

/*  CORE COMPONENTS & UTILITIES */
.utils__visibilityHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%); }

.utils__content {
  padding: 2.5rem;
  max-width: 130rem;
  margin: 0 auto; }
  @media (max-width: 767px) {
    .utils__content {
      padding: 2.5rem 0.83rem !important;
      overflow-x: hidden; } }

.utils__title {
  font-size: 1.41rem;
  color: #081616; }

.utils__titleDescription {
  color: #c0bdd0; }

.utils__scrollable {
  overflow: auto; }

.utils__example {
  padding: 2.08rem;
  border: 1px solid #e4e9f0; }
  .utils__example:before {
    content: 'EXAMPLE';
    display: block;
    margin-bottom: 1.25rem;
    color: #74708d; }

.utils__link--underlined {
  border-bottom: 1px solid rgba(57, 55, 73, 0.25); }
  .utils__link--underlined:hover {
    border-bottom-color: rgba(0, 136, 255, 0.2); }

.utils__link--blue {
  color: #08f; }
  .utils__link--blue:hover {
    color: #0072d6; }
  .utils__link--blue.link-underlined {
    border-bottom: 1px solid rgba(0, 136, 255, 0.25); }
    .utils__link--blue.link-underlined:hover {
      border-bottom-color: rgba(0, 136, 255, 0.2); }

.utils__iconsBlock {
  padding: 0 0 2.5rem;
  text-align: center; }
  .utils__iconsBlock p {
    height: 2.5rem; }
  .utils__iconsBlock li {
    text-align: center;
    display: inline-block;
    width: 3.33rem;
    height: 3.33rem;
    line-height: 3.33rem;
    font-size: 3rem;
    padding: 0.83rem 2.5rem;
    box-sizing: content-box;
    border-radius: 3px;
    background: #eef0f4;
    margin: 0.83rem; }
    .utils__iconsBlock li:hover {
      background: #222034;
      color: #fff; }
  .utils__iconsBlock .tooltip {
    font-size: 1.5rem; }

.utils__scrollTable {
  white-space: nowrap !important; }
  .utils__scrollTable .ant-table-body {
    overflow-x: auto !important; }

@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

/*  DEFAULT MEASUREMENTS STYLES */
.font-size-0 {
  font-size: 0rem; }

.font-size-10 {
  font-size: 0.83rem; }

.font-size-12 {
  font-size: 1rem; }

.font-size-14 {
  font-size: 1.16rem; }

.font-size-16 {
  font-size: 1.33rem; }

.font-size-18 {
  font-size: 1.5rem; }

.font-size-20 {
  font-size: 1.66rem; }

.font-size-24 {
  font-size: 2rem; }

.font-size-26 {
  font-size: 2.16rem; }

.font-size-30 {
  font-size: 2.5rem; }

.font-size-36 {
  font-size: 3rem; }

.font-size-40 {
  font-size: 3.33rem; }

.font-size-50 {
  font-size: 4.16rem; }

.font-size-60 {
  font-size: 5rem; }

.font-size-70 {
  font-size: 5.83rem; }

.font-size-80 {
  font-size: 6.66rem; }

.height-100 {
  height: 8.33rem; }

.height-200 {
  height: 16.66rem; }

.height-300 {
  height: 25rem; }

.height-400 {
  height: 33.33rem; }

.height-500 {
  height: 41.66rem; }

.height-600 {
  height: 50rem; }

.height-700 {
  height: 58.33rem; }

.min-height-100 {
  min-height: 8.33rem; }

.min-height-200 {
  min-height: 16.66rem; }

.min-height-300 {
  min-height: 25rem; }

.min-height-400 {
  min-height: 33.33rem; }

.min-height-500 {
  min-height: 41.66rem; }

.min-height-600 {
  min-height: 50rem; }

.min-height-700 {
  min-height: 58.33rem; }

.width-50 {
  width: 4.16rem; }

.width-75 {
  width: 6.25rem; }

.width-100 {
  width: 8.33rem; }

.width-150 {
  width: 12.5rem; }

.width-200 {
  width: 16.66rem; }

.width-300 {
  width: 25rem; }

.width-400 {
  width: 33.33rem; }

.width-500 {
  width: 41.66rem; }

.width-600 {
  width: 50rem; }

.width-700 {
  width: 58.33rem; }

.width-30p {
  width: 30%; }

.width-50p {
  width: 50%; }

.width-60p {
  width: 60%; }

.width-100p {
  width: 100%; }

.max-width-50 {
  max-width: 4.16rem; }

.max-width-75 {
  max-width: 6.25rem; }

.max-width-100 {
  max-width: 8.33rem; }

.max-width-200 {
  max-width: 16.66rem; }

.max-width-300 {
  max-width: 25rem; }

.max-width-400 {
  max-width: 33.33rem; }

.max-width-500 {
  max-width: 41.66rem; }

.max-width-600 {
  max-width: 50rem; }

.max-width-700 {
  max-width: 58.33rem; }

.max-width-30p {
  max-width: 30%; }

.max-width-50p {
  max-width: 50%; }

.max-width-60p {
  max-width: 60%; }

.max-width-100p {
  max-width: 100% !important; }

@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

/*  THEME SETTINGS STYLES */
.settings__fixedWidth {
  max-width: 1420px;
  margin: 0 auto; }
  .settings__fixedWidth:not(.settings__menuTop) .utils__content {
    padding-right: 0; }
  .settings__fixedWidth:not(.settings__menuTop) div[class^='style-module__inner'] {
    margin-right: 0; }
  @media (max-width: 1445px) {
    .settings__fixedWidth:not(.settings__menuTop) .utils__content {
      padding-right: 2.5rem; }
    .settings__fixedWidth:not(.settings__menuTop) div[class^='style-module__inner'] {
      margin-right: 2.5rem; } }
  .settings__fixedWidth.settings__menuTop .utils__content {
    padding-left: 0;
    padding-right: 0; }
  .settings__fixedWidth.settings__menuTop div[class^='style-module__inner'] {
    margin: 0; }
  @media (max-width: 1445px) {
    .settings__fixedWidth.settings__menuTop .utils__content {
      padding-left: 2.5rem;
      padding-right: 2.5rem; }
    .settings__fixedWidth.settings__menuTop div[class^='style-module__inner'] {
      margin: 0 2.5rem; } }

.settings__squaredBorders .card {
  border-radius: 0; }

.settings__menuShadow .ant-layout-sider {
  position: relative;
  z-index: 1; }

.settings__borderLess .card {
  border: none; }

.settings__borderLess .card-header {
  padding-top: 1.66rem;
  padding-bottom: 0;
  border-bottom: 0; }

.settings__borderLess .card-footer {
  padding-top: 0;
  border-top: 0; }

.settings__borderLess .utils__title {
  text-transform: uppercase;
  font-size: 1.33rem; }

@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

/* GRID */
.row {
  margin-left: -1.25rem;
  margin-right: -1.25rem; }

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9 {
  padding-left: 1.25rem;
  padding-right: 1.25rem; }

.container-fluid {
  padding-left: 1.25rem;
  padding-right: 1.25rem; }

@media (max-width: 1599px) {
  .hidden-xxl-down {
    display: none; } }

@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

/* CARDS */
.card,
.card-header,
.list-group-item,
.card-footer {
  border-color: #e4e9f0; }

.card-header,
.card-footer {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  background: #fff; }
  .card-header:last-child,
  .card-footer:last-child {
    border-radius: 0 0 calc(7px - 1px) calc(7px - 1px); }
  .card-header:first-child,
  .card-footer:first-child {
    border-radius: calc(7px - 1px) calc(7px - 1px) 0 0; }
  @media (max-width: 991px) {
    .card-header,
    .card-footer {
      padding-left: 1.66rem;
      padding-right: 1.66rem; } }

.card-body {
  flex: 1 1 auto;
  padding: 2.08rem 2.5rem; }
  @media (max-width: 991px) {
    .card-body {
      padding-left: 1.66rem;
      padding-right: 1.66rem; } }

.card {
  margin-bottom: 2.5rem;
  border-radius: 7px; }
  .card--withShadow {
    transition: box-shadow 0.1s ease-in-out; }
    .card--withShadow:hover {
      box-shadow: 0 0 2.25rem #e1e1e1; }
  .card--fullHeight {
    height: calc(100% - 2.3rem); }
  .utils__content > .card:last-child {
    margin-bottom: 0; }

.card--example {
  border: 1px solid #e4e9f0 !important; }
  .card--example .card-header > h5 {
    margin-bottom: 0; }

@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

/* UTILITIES */
.text-muted {
  color: #c0bdd0 !important; }

.text-primary {
  color: #4b58dd; }

.text-success {
  color: #46be8a; }

.text-info {
  color: #0887c9; }

.text-warning {
  color: #FE7F16; }

.text-danger {
  color: #ea366d; }

.text-default {
  color: #acb7bf; }

.text-black {
  color: #081616; }

.bg-primary {
  background-color: #4b58dd !important; }

.bg-success {
  background-color: #46be8a !important; }

.bg-info {
  background-color: #0887c9 !important; }

.bg-warning {
  background-color: #FE7F16 !important; }

.bg-danger {
  background-color: #ea366d !important; }

.bg-inverse {
  background-color: #222034 !important; }

.bg-faded {
  background-color: #f2f4f8 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-default {
  background-color: #acb7bf !important; }

.progress {
  background: #f2f4f8; }
  .progress-bar {
    height: 1.5rem; }

@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

/* BUTTONS */
.btn-link {
  color: #74708d; }
  .btn-link:focus, .btn-link:hover {
    color: #615d7c;
    text-decoration: none; }

.btn {
  outline: none !important;
  box-shadow: none !important;
  transition: color 0.2s ease-in-out, background 0.2s ease-in-out, border 0.2s ease-in-out;
  padding: 0.66rem 1.33rem;
  line-height: 1.7; }
  .btn:hover, .btn:active {
    transition: color 0.1s ease-in-out, background 0.1s ease-in-out, border 0.1s ease-in-out; }
  .btn.btn-rounded {
    border-radius: 100px; }
  .btn.btn-squared {
    border-radius: 0; }
  .btn.btn-sm {
    padding: 0.33rem 0.91rem; }
  .btn.btn-lg {
    padding: 1rem 1.66rem; }
  .btn.btn-link {
    color: #74708d;
    border-color: transparent !important;
    background: none !important;
    text-decoration: none; }
    .btn.btn-link:hover, .btn.btn-link:active, .btn.btn-link:focus, .btn.btn-link.active {
      color: #08f; }
  .btn.btn-icon {
    padding: 0.83rem;
    line-height: 1rem;
    text-decoration: none !important; }
    .btn.btn-icon.btn-sm {
      padding: 0.58rem; }
    .btn.btn-icon.btn-lg {
      padding: 1.66rem; }

.btn-group-vertical > label {
  margin-bottom: 0; }

.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate; }
  .btn-group-justified > .btn-group {
    float: none;
    display: table-cell;
    width: 1%; }
    .btn-group-justified > .btn-group > .btn {
      width: 100%; }
  .btn-group-justified.btn-group-vertical {
    display: block; }
    .btn-group-justified.btn-group-vertical > .btn-group {
      display: block;
      width: 100%; }

.btn,
.show > .btn {
  color: #74708d;
  background-color: #fff;
  border-color: #d2d9e5; }
  .btn:hover, .btn:active,
  .show > .btn:hover,
  .show > .btn:active {
    background-color: #dbdee4;
    border-color: #dbdee4; }
  .btn:hover:active, .btn:focus, .btn.active,
  .show > .btn:hover:active,
  .show > .btn:focus,
  .show > .btn.active {
    background: #cfd3db;
    border-color: #cfd3db; }
  .btn.btn-default, .btn.btn-primary, .btn.btn-secondary, .btn.btn-success, .btn.btn-info, .btn.btn-warning, .btn.btn-danger,
  .show > .btn.btn-default,
  .show > .btn.btn-primary,
  .show > .btn.btn-secondary,
  .show > .btn.btn-success,
  .show > .btn.btn-info,
  .show > .btn.btn-warning,
  .show > .btn.btn-danger {
    color: #fff !important; }
  .btn.btn-default,
  .show > .btn.btn-default {
    background-color: #acb7bf;
    border-color: #acb7bf; }
    .btn.btn-default:hover, .btn.btn-default:active,
    .show > .btn.btn-default:hover,
    .show > .btn.btn-default:active {
      background-color: #bdc6cc;
      border-color: #bdc6cc; }
    .btn.btn-default:hover:active, .btn.btn-default:focus, .btn.btn-default.active,
    .show > .btn.btn-default:hover:active,
    .show > .btn.btn-default:focus,
    .show > .btn.btn-default.active {
      background: #9ba8b2;
      border-color: #9ba8b2; }
  .btn.btn-primary,
  .show > .btn.btn-primary {
    background-color: #4b58dd;
    border-color: #4b58dd; }
    .btn.btn-primary:hover, .btn.btn-primary:active,
    .show > .btn.btn-primary:hover,
    .show > .btn.btn-primary:active {
      background-color: #6570e2;
      border-color: #6570e2; }
    .btn.btn-primary:hover:active, .btn.btn-primary:focus, .btn.btn-primary.active,
    .show > .btn.btn-primary:hover:active,
    .show > .btn.btn-primary:focus,
    .show > .btn.btn-primary.active {
      background: #3140d8 !important;
      border-color: #3140d8 !important; }
  .btn.btn-secondary,
  .show > .btn.btn-secondary {
    background-color: #6a7a84;
    border-color: #6a7a84; }
    .btn.btn-secondary:hover, .btn.btn-secondary:active,
    .show > .btn.btn-secondary:hover,
    .show > .btn.btn-secondary:active {
      background-color: #798993;
      border-color: #798993; }
    .btn.btn-secondary:hover:active, .btn.btn-secondary:focus, .btn.btn-secondary.active,
    .show > .btn.btn-secondary:hover:active,
    .show > .btn.btn-secondary:focus,
    .show > .btn.btn-secondary.active {
      background: #5c6a73;
      border-color: #5c6a73; }
  .btn.btn-success,
  .show > .btn.btn-success {
    background-color: #46be8a;
    border-color: #46be8a; }
    .btn.btn-success:hover, .btn.btn-success:active,
    .show > .btn.btn-success:hover,
    .show > .btn.btn-success:active {
      background-color: #5dc698;
      border-color: #5dc698; }
    .btn.btn-success:hover:active, .btn.btn-success:focus, .btn.btn-success.active,
    .show > .btn.btn-success:hover:active,
    .show > .btn.btn-success:focus,
    .show > .btn.btn-success.active {
      background: #3caa7a;
      border-color: #3caa7a; }
  .btn.btn-info,
  .show > .btn.btn-info {
    background-color: #0887c9;
    border-color: #0887c9; }
    .btn.btn-info:hover, .btn.btn-info:active,
    .show > .btn.btn-info:hover,
    .show > .btn.btn-info:active {
      background-color: #099be6;
      border-color: #099be6; }
    .btn.btn-info:hover:active, .btn.btn-info:focus, .btn.btn-info.active,
    .show > .btn.btn-info:hover:active,
    .show > .btn.btn-info:focus,
    .show > .btn.btn-info.active {
      background: #0773ac;
      border-color: #0773ac; }
  .btn.btn-warning,
  .show > .btn.btn-warning {
    background-color: #FE7F16;
    border-color: #FE7F16; }
    .btn.btn-warning:hover, .btn.btn-warning:active,
    .show > .btn.btn-warning:hover,
    .show > .btn.btn-warning:active {
      background-color: #fe9034;
      border-color: #fe9034; }
    .btn.btn-warning:hover:active, .btn.btn-warning:focus, .btn.btn-warning.active,
    .show > .btn.btn-warning:hover:active,
    .show > .btn.btn-warning:focus,
    .show > .btn.btn-warning.active {
      background: #f46f01;
      border-color: #f46f01; }
  .btn.btn-danger,
  .show > .btn.btn-danger {
    background-color: #ea366d;
    border-color: #ea366d; }
    .btn.btn-danger:hover, .btn.btn-danger:active,
    .show > .btn.btn-danger:hover,
    .show > .btn.btn-danger:active {
      background-color: #ed5281;
      border-color: #ed5281; }
    .btn.btn-danger:hover:active, .btn.btn-danger:focus, .btn.btn-danger.active,
    .show > .btn.btn-danger:hover:active,
    .show > .btn.btn-danger:focus,
    .show > .btn.btn-danger.active {
      background: #e71a59;
      border-color: #e71a59; }
  .btn.btn-outline-default, .btn.btn-outline-primary, .btn.btn-outline-secondary, .btn.btn-outline-success, .btn.btn-outline-info, .btn.btn-outline-warning, .btn.btn-outline-danger,
  .show > .btn.btn-outline-default,
  .show > .btn.btn-outline-primary,
  .show > .btn.btn-outline-secondary,
  .show > .btn.btn-outline-success,
  .show > .btn.btn-outline-info,
  .show > .btn.btn-outline-warning,
  .show > .btn.btn-outline-danger {
    background-color: #fff; }
  .btn.btn-outline-default,
  .show > .btn.btn-outline-default {
    border-color: #acb7bf;
    color: #acb7bf; }
    .btn.btn-outline-default:hover, .btn.btn-outline-default:active,
    .show > .btn.btn-outline-default:hover,
    .show > .btn.btn-outline-default:active {
      background-color: #bdc6cc;
      border-color: #bdc6cc;
      color: #fff; }
    .btn.btn-outline-default:hover:active, .btn.btn-outline-default:focus, .btn.btn-outline-default.active,
    .show > .btn.btn-outline-default:hover:active,
    .show > .btn.btn-outline-default:focus,
    .show > .btn.btn-outline-default.active {
      background: #9ba8b2;
      border-color: #9ba8b2;
      color: #fff; }
  .btn.btn-outline-primary,
  .show > .btn.btn-outline-primary {
    border-color: #4b58dd;
    color: #4b58dd; }
    .btn.btn-outline-primary:hover, .btn.btn-outline-primary:active,
    .show > .btn.btn-outline-primary:hover,
    .show > .btn.btn-outline-primary:active {
      background-color: #6570e2;
      border-color: #6570e2;
      color: #fff; }
    .btn.btn-outline-primary:hover:active, .btn.btn-outline-primary:focus, .btn.btn-outline-primary.active,
    .show > .btn.btn-outline-primary:hover:active,
    .show > .btn.btn-outline-primary:focus,
    .show > .btn.btn-outline-primary.active {
      background: #3140d8;
      border-color: #3140d8;
      color: #fff; }
  .btn.btn-outline-secondary,
  .show > .btn.btn-outline-secondary {
    border-color: #6a7a84;
    color: #6a7a84; }
    .btn.btn-outline-secondary:hover, .btn.btn-outline-secondary:active,
    .show > .btn.btn-outline-secondary:hover,
    .show > .btn.btn-outline-secondary:active {
      background-color: #798993;
      border-color: #798993;
      color: #fff; }
    .btn.btn-outline-secondary:hover:active, .btn.btn-outline-secondary:focus, .btn.btn-outline-secondary.active,
    .show > .btn.btn-outline-secondary:hover:active,
    .show > .btn.btn-outline-secondary:focus,
    .show > .btn.btn-outline-secondary.active {
      background: #5c6a73;
      border-color: #5c6a73;
      color: #fff; }
  .btn.btn-outline-success,
  .show > .btn.btn-outline-success {
    border-color: #46be8a;
    color: #46be8a; }
    .btn.btn-outline-success:hover, .btn.btn-outline-success:active,
    .show > .btn.btn-outline-success:hover,
    .show > .btn.btn-outline-success:active {
      background-color: #5dc698;
      border-color: #5dc698;
      color: #fff; }
    .btn.btn-outline-success:hover:active, .btn.btn-outline-success:focus, .btn.btn-outline-success.active,
    .show > .btn.btn-outline-success:hover:active,
    .show > .btn.btn-outline-success:focus,
    .show > .btn.btn-outline-success.active {
      background: #3caa7a;
      border-color: #3caa7a;
      color: #fff; }
  .btn.btn-outline-info,
  .show > .btn.btn-outline-info {
    border-color: #0887c9;
    color: #0887c9; }
    .btn.btn-outline-info:hover, .btn.btn-outline-info:active,
    .show > .btn.btn-outline-info:hover,
    .show > .btn.btn-outline-info:active {
      background-color: #099be6;
      border-color: #099be6;
      color: #fff; }
    .btn.btn-outline-info:hover:active, .btn.btn-outline-info:focus, .btn.btn-outline-info.active,
    .show > .btn.btn-outline-info:hover:active,
    .show > .btn.btn-outline-info:focus,
    .show > .btn.btn-outline-info.active {
      background: #0773ac;
      border-color: #0773ac;
      color: #fff; }
  .btn.btn-outline-warning,
  .show > .btn.btn-outline-warning {
    border-color: #FE7F16;
    color: #FE7F16; }
    .btn.btn-outline-warning:hover, .btn.btn-outline-warning:active,
    .show > .btn.btn-outline-warning:hover,
    .show > .btn.btn-outline-warning:active {
      background-color: #fe9034;
      border-color: #fe9034;
      color: #fff; }
    .btn.btn-outline-warning:hover:active, .btn.btn-outline-warning:focus, .btn.btn-outline-warning.active,
    .show > .btn.btn-outline-warning:hover:active,
    .show > .btn.btn-outline-warning:focus,
    .show > .btn.btn-outline-warning.active {
      background: #f46f01;
      border-color: #f46f01;
      color: #fff; }
  .btn.btn-outline-danger,
  .show > .btn.btn-outline-danger {
    border-color: #ea366d;
    color: #ea366d; }
    .btn.btn-outline-danger:hover, .btn.btn-outline-danger:active,
    .show > .btn.btn-outline-danger:hover,
    .show > .btn.btn-outline-danger:active {
      background-color: #ed5281;
      border-color: #ed5281;
      color: #fff; }
    .btn.btn-outline-danger:hover:active, .btn.btn-outline-danger:focus, .btn.btn-outline-danger.active,
    .show > .btn.btn-outline-danger:hover:active,
    .show > .btn.btn-outline-danger:focus,
    .show > .btn.btn-outline-danger.active {
      background: #e71a59;
      border-color: #e71a59;
      color: #fff; }

@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

/* TABLE */
.table {
  width: 100% !important; }
  .table thead th {
    border-bottom: 1px solid #e4e9f0;
    outline: none !important; }
    .table thead th:focus {
      background: #e4e9f0; }
  .table td,
  .table th {
    border-color: #e4e9f0; }
    .table td:focus,
    .table th:focus {
      background-color: #e4e9f0; }
  .table tbody tr:first-child td {
    border-top: none; }
  .table.table-hover tbody tr:hover {
    background: #f2f4f8; }
  .table tr.active,
  .table th.active {
    background: #f2f4f8; }
  .table .thead-inverse th {
    background: #222034; }
  .table .thead-default th {
    background: #f2f4f8; }
  .table.table-striped tbody tr:nth-of-type(odd) {
    background: #f2f4f8; }
  .table.table-vertical-middle td,
  .table.table-vertical-middle th {
    vertical-align: middle; }

.table-inverse {
  color: #b8beca; }
  .table-inverse thead th {
    border-bottom-color: #393749;
    color: #fff; }
  .table-inverse th,
  .table-inverse td {
    border-top-color: #393749; }
  .table-inverse th,
  .table-inverse td,
  .table-inverse thead th {
    background: #222034; }
    .table-inverse th:focus,
    .table-inverse td:focus,
    .table-inverse thead th:focus {
      background: #222034; }
  .table-inverse tr.active,
  .table-inverse th.active {
    background: #eef0f4; }

@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

/* TYPOGRAPHY */
.mark,
mark {
  background: #FE5723;
  color: #fff; }

.blockquote {
  background: #f2f4f8;
  padding: 30px;
  border-left: 3px solid #d2d9e5;
  font-size: 1.25rem; }
  .blockquote-reverse {
    text-align: right;
    border-left: none;
    border-right: 3px solid #d2d9e5; }
  .blockquote-footer {
    margin-top: 1.66rem;
    color: #b8beca; }

@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

/* BREADCRUMB */
.breadcrumb {
  background: #f2f4f8; }
  .breadcrumb--custom {
    display: inline-block;
    padding: 0;
    margin-bottom: 0;
    background: none; }
    .breadcrumb--custom li {
      padding: 0; }
      .breadcrumb--custom li:before {
        display: none !important; }
      .breadcrumb--custom li > a,
      .breadcrumb--custom li > span {
        display: inline-block;
        padding: 9px 30px;
        position: relative;
        background: #eef0f4;
        margin: 0 0.66rem 0.5rem;
        font-size: 13px; }
        .breadcrumb--custom li > a:before,
        .breadcrumb--custom li > span:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: -10px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 39px 10px 0 0;
          border-color: transparent #eef0f4 transparent transparent;
          transition: all 0.2s ease-in-out; }
        .breadcrumb--custom li > a:after,
        .breadcrumb--custom li > span:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: -10px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 0 39px 10px;
          border-color: transparent transparent transparent #eef0f4;
          transition: all 0.2s ease-in-out; }
      .breadcrumb--custom li > a {
        transition: all 0.2s ease-in-out; }
        .breadcrumb--custom li > a:hover, .breadcrumb--custom li > a.active {
          background: #08f;
          color: #fff; }
          .breadcrumb--custom li > a:hover:before, .breadcrumb--custom li > a.active:before {
            border-color: transparent #08f transparent transparent; }
          .breadcrumb--custom li > a:hover:after, .breadcrumb--custom li > a.active:after {
            border-color: transparent transparent transparent #08f; }
      .breadcrumb--custom li:first-child > a,
      .breadcrumb--custom li:first-child > span {
        margin-left: 0; }
        .breadcrumb--custom li:first-child > a:before,
        .breadcrumb--custom li:first-child > span:before {
          display: none; }
      .breadcrumb--custom li:last-child > a:after,
      .breadcrumb--custom li:last-child > span:after {
        display: none; }
      .breadcrumb--custom li:after {
        padding: 0;
        content: ''; }

@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

/* FORMS */
input::-webkit-input-placeholder {
  color: #b8beca !important; }

input::-moz-placeholder {
  color: #b8beca !important; }

input:-moz-placeholder {
  color: #b8beca !important; }

input:-ms-input-placeholder {
  color: #b8beca !important; }

.form-actions {
  border-top: 1px solid #e4e9f0;
  padding-top: 1.66rem;
  margin: 1.66rem 0; }

.form-control {
  font-family: "Work Sans", sans-serif !important;
  padding: 0.91rem 1.33rem;
  border-color: #e4e9f0;
  color: #74708d; }
  .form-control.form-control-rounded {
    border-radius: 500px; }
  .form-control:focus {
    border-color: #4b58dd; }
  .form-control.form-control-sm {
    padding: 0.58rem 0.66rem; }

.col-form-label {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; }

.form-control:disabled,
.form-control[readonly] {
  background-color: #eef0f4; }

.input-group-addon {
  border-color: #e4e9f0;
  background-color: #eef0f4; }

span.input-group-addon {
  outline: none !important; }

.form-group.has-danger .form-control {
  border-color: #ea366d; }

.form-group.has-success .form-control {
  border-color: #46be8a; }

.form-group.has-warning .form-control {
  border-color: #FE7F16; }

.form-group.has-focused .form-control {
  border-color: #4b58dd; }

.form-input-icon {
  position: relative; }
  .form-input-icon > i {
    color: #b8beca;
    position: absolute;
    margin: 1.08rem 0.16rem 0.33rem 0.83rem;
    z-index: 3;
    width: 1.33rem;
    font-size: 1.33rem;
    text-align: center;
    left: 0; }
  .form-input-icon .form-control {
    padding-left: 2.83rem; }
  .form-input-icon.form-input-icon-right > i {
    left: auto;
    right: 0.25rem;
    margin: 1.08rem 0.83rem 0.33rem 0.16rem; }
  .form-input-icon.form-input-icon-right .form-control {
    padding-left: 1.33rem;
    padding-right: 2.83rem; }

@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

/* DROPDOWNS */
.dropdown-menu {
  border-color: #d2d9e5;
  top: 115%;
  padding: 0.66rem 0; }
  .dropdown-menu .dropdown-icon {
    margin-right: 0.41rem; }
  .dropdown-menu .dropdown-divider {
    background-color: #e4e9f0; }
  .dropdown-menu .dropdown-header {
    padding: 0.58rem 1.25rem;
    color: #615d7c;
    font-size: 1.08rem;
    font-weight: 600; }
  .dropdown-menu .dropdown-item {
    color: #74708d;
    background: none;
    padding: 0.25rem 1.25rem;
    transition: color 0.2s ease-in-out; }
    .dropdown-menu .dropdown-item a {
      color: #74708d; }
      .dropdown-menu .dropdown-item a:hover, .dropdown-menu .dropdown-item a:active {
        background: none;
        color: #08f; }
    .dropdown-menu .dropdown-item.disabled {
      cursor: not-allowed;
      color: #e4e9f0 !important; }
      .dropdown-menu .dropdown-item.disabled a {
        color: #e4e9f0 !important;
        cursor: not-allowed; }
    .dropdown-menu .dropdown-item.active {
      background: #eef0f4 !important; }
    .dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:active, .dropdown-menu .dropdown-item:focus {
      background: none;
      color: #08f; }

.dropdown-animate {
  overflow: hidden; }
  .dropdown-animate > .dropdown-item,
  .dropdown-animate .dropdown-header {
    animation-name: dropdown-slide-left;
    animation-duration: 0.5s;
    animation-fill-mode: both; }
    .dropdown-animate > .dropdown-item:nth-child(1),
    .dropdown-animate .dropdown-header:nth-child(1) {
      animation-delay: 0.02s; }
    .dropdown-animate > .dropdown-item:nth-child(2),
    .dropdown-animate .dropdown-header:nth-child(2) {
      animation-delay: 0.04s; }
    .dropdown-animate > .dropdown-item:nth-child(3),
    .dropdown-animate .dropdown-header:nth-child(3) {
      animation-delay: 0.06s; }
    .dropdown-animate > .dropdown-item:nth-child(4),
    .dropdown-animate .dropdown-header:nth-child(4) {
      animation-delay: 0.08s; }
    .dropdown-animate > .dropdown-item:nth-child(5),
    .dropdown-animate .dropdown-header:nth-child(5) {
      animation-delay: 0.1s; }
    .dropdown-animate > .dropdown-item:nth-child(6),
    .dropdown-animate .dropdown-header:nth-child(6) {
      animation-delay: 0.12s; }
    .dropdown-animate > .dropdown-item:nth-child(7),
    .dropdown-animate .dropdown-header:nth-child(7) {
      animation-delay: 0.14s; }
    .dropdown-animate > .dropdown-item:nth-child(8),
    .dropdown-animate .dropdown-header:nth-child(8) {
      animation-delay: 0.16s; }
    .dropdown-animate > .dropdown-item:nth-child(9),
    .dropdown-animate .dropdown-header:nth-child(9) {
      animation-delay: 0.18s; }
    .dropdown-animate > .dropdown-item:nth-child(10),
    .dropdown-animate .dropdown-header:nth-child(10) {
      animation-delay: 0.2s; }

.show > .dropdown-menu,
.dropdown-menu.open > .dropdown-menu {
  animation-name: dropdown-slide-bottom;
  animation-duration: 0.15s;
  animation-fill-mode: both;
  animation-delay: 0.05s;
  display: block; }

.show.dropup > .dropdown-menu,
.dropdown-menu.open.dropup > .dropdown-menu {
  animation-name: dropdown-slide-top; }

.dropdown .dropdown-toggle {
  position: relative; }
  .dropdown .dropdown-toggle:after {
    margin-top: -0.09rem;
    color: #d2d9e5;
    transition: color 0.2s ease-in-out; }
  .dropdown .dropdown-toggle > i {
    color: #d2d9e5;
    transition: color 0.2s ease-in-out; }

.dropdown:hover .dropdown-toggle > i, .dropdown.show .dropdown-toggle > i {
  color: #b8beca; }

.dropdown:hover .dropdown-toggle:after, .dropdown.show .dropdown-toggle:after {
  color: #b8beca; }

@keyframes dropdown-slide-left {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0); }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes dropdown-slide-bottom {
  0% {
    opacity: 0;
    transform: translate3d(0, -5px, 0); }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes dropdown-slide-top {
  0% {
    opacity: 0;
    transform: translate3d(0, 5px, 0); }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

/* SELECTBOXES */
select {
  appearance: none; }

select.form-control {
  color: #74708d;
  height: auto;
  background: #fff center right no-repeat url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAFCAYAAABB9hwOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA25pVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpiNWZkMzNlMC0zNTcxLTI4NDgtYjA3NC01ZTRhN2RjMWVmNjEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6ODZDNDdFRTkxRTJBMTFFNjg0MUM5MTMwMjYwRDYwRDkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6ODZDNDdFRTgxRTJBMTFFNjg0MUM5MTMwMjYwRDYwRDkiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RTUxRUI3MDZEQjk4MTFFNUI1NDA5QTcyNTlFQzRERTYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RTUxRUI3MDdEQjk4MTFFNUI1NDA5QTcyNTlFQzRERTYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz69wtu7AAAAe0lEQVR42mLce+zSOVFhYUMGNHDv4cOd/q6WHgxkAqbvP77H/P339zey4Nfv3z7ceXA/hoECwCQnLXPtw8eP05EFHz15WuRm7/CGIoNBhLCgUPnPX79egdgv37w+qKmqOp+BQsAEpX8wMTFm/fnz5/P/f//DGagAAAIMAKIuMR+q/rU9AAAAAElFTkSuQmCC"); }

select.form-control[multiple] {
  background: #fff; }

select.form-control:not([size]):not([multiple]) {
  height: auto; }

@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

/* BADGES */
.badge {
  font-weight: normal;
  background: #fff;
  color: #6a7a84; }
  .badge.badge-default {
    background: #acb7bf;
    color: #fff; }
  .badge.badge-primary {
    background: #4b58dd;
    color: #fff; }
  .badge.badge-secondary {
    background: #6a7a84;
    color: #fff; }
  .badge.badge-success {
    background: #46be8a;
    color: #fff; }
  .badge.badge-info {
    background: #0887c9;
    color: #fff; }
  .badge.badge-danger {
    background: #ea366d;
    color: #fff; }
  .badge.badge-warning {
    background: #FE7F16;
    color: #fff; }

.btn .badge {
  top: 1px; }

@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

/* CAROUSEL */
.carousel-indicators li {
  background: #eef0f4;
  opacity: 0.5; }
  .carousel-indicators li.active {
    opacity: 1;
    background: #eef0f4; }

@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

/* COLLAPSE */
.accordion .card {
  border-radius: 0;
  margin-bottom: 0;
  border-bottom-width: 0; }
  .accordion .card:first-child {
    border-radius: 5px 5px 0 0; }
  .accordion .card:last-child {
    border-radius: 0 0 5px 5px;
    border-bottom-width: 1px; }
  .accordion .card .card-header {
    cursor: pointer; }
    .accordion .card .card-header .card-title {
      margin-bottom: 0; }
    .accordion .card .card-header.collapsed {
      border: none; }

.accordion.accordion-margin-bottom .card {
  border-radius: 5px;
  margin-bottom: 1.25rem;
  border-bottom-width: 1px; }

.accordion .accordion-indicator {
  padding-top: 1px;
  color: #b8beca; }
  .accordion .accordion-indicator .plus {
    display: none; }
  .accordion .accordion-indicator .minus {
    display: inline; }

.accordion .collapsed .accordion-indicator .plus {
  display: inline; }

.accordion .collapsed .accordion-indicator .minus {
  display: none; }

@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

/* MODAL */
.modal .modal-content {
  border: none; }

.modal .modal-header .close {
  outline: none !important;
  width: 30px;
  height: 30px;
  display: inline-block;
  line-height: 30px;
  text-align: center; }
  .modal .modal-header .close span {
    position: relative;
    top: 3px; }

.modal.modal-size-small {
  padding-left: 10px;
  padding-right: 10px; }
  .modal.modal-size-small .modal-dialog {
    max-width: 300px;
    width: auto; }
    @media (max-width: 575px) {
      .modal.modal-size-small .modal-dialog {
        max-width: none; } }

.modal.modal-size-large {
  padding-left: 10px;
  padding-right: 10px; }
  .modal.modal-size-large .modal-dialog {
    max-width: 980px;
    width: auto; }

.modal-backdrop {
  background: #081616; }
  .modal-backdrop.in {
    opacity: 0.3; }

.modal-body {
  padding: 1.66rem 4.16rem;
  min-width: 75rem; }
  @media (max-width: 991px) {
    .modal-body {
      min-width: 0; } }

@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

/* ALERTS */
.alert {
  border: none;
  font-weight: normal;
  color: #fff; }
  .alert .close {
    color: #fff;
    opacity: 0.5;
    outline: none !important; }
    .alert .close:hover {
      opacity: 1; }
  .alert a,
  .alert .alert-link {
    font-weight: normal;
    color: #fff;
    opacity: 0.7;
    transition: color 0.2s ease-in-out; }
    .alert a:hover,
    .alert .alert-link:hover {
      opacity: 1;
      color: #fff; }
  .alert.alert-default {
    background: #acb7bf; }
  .alert.alert-primary {
    background: #4b58dd; }
  .alert.alert-secondary {
    background: #6a7a84; }
  .alert.alert-success {
    background: #46be8a; }
  .alert.alert-info {
    background: #0887c9; }
  .alert.alert-warning {
    background: #FE7F16; }
  .alert.alert-danger {
    background: #ea366d; }

@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

/* PAGINATION */
.pagination .page-link {
  border-color: #e4e9f0;
  color: #74708d;
  outline: none;
  margin-bottom: 0.83rem; }
  .pagination .page-link:hover, .pagination .page-link:focus {
    background: #acb7bf;
    color: #fff;
    border-color: #acb7bf; }

.pagination .page-item.disabled .page-link {
  background: #f2f4f8; }

.pagination .page-item.active .page-link {
  background: #4b58dd;
  border-color: #4b58dd; }

.pager li > a {
  border-color: #b8beca;
  color: #393749;
  outline: none;
  border-radius: 3px; }
  .pager li > a:hover, .pager li > a:focus {
    background: #acb7bf;
    color: #fff;
    border-color: #acb7bf; }

@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

/* TABS */
.nav-tabs-horizontal .nav-tabs {
  border-bottom: 1px solid #e4e9f0; }
  .nav-tabs-horizontal .nav-tabs .nav-item {
    margin-bottom: -2px;
    cursor: pointer; }
    .nav-tabs-horizontal .nav-tabs .nav-item .nav-link {
      border: none;
      border-bottom: 3px solid transparent; }
      .nav-tabs-horizontal .nav-tabs .nav-item .nav-link.active, .nav-tabs-horizontal .nav-tabs .nav-item .nav-link:focus {
        border-bottom-color: #4b58dd !important; }
      .nav-tabs-horizontal .nav-tabs .nav-item .nav-link:hover {
        border-bottom-color: #b8beca; }

.nav-tabs-vertical:after {
  clear: both;
  content: '';
  display: block; }

.nav-tabs-vertical .nav-tabs {
  border-bottom: none;
  border-right: 1px solid #e4e9f0;
  float: left;
  margin-right: 30px;
  display: block; }
  .nav-tabs-vertical .nav-tabs .nav-item {
    margin: 0 -2px 1px 0;
    float: none;
    cursor: pointer; }
    .nav-tabs-vertical .nav-tabs .nav-item .nav-link {
      border: none;
      border-right: 3px solid transparent;
      padding-left: 0;
      border-radius: 0; }
      .nav-tabs-vertical .nav-tabs .nav-item .nav-link.active, .nav-tabs-vertical .nav-tabs .nav-item .nav-link:focus {
        border-right-color: #4b58dd !important; }
      .nav-tabs-vertical .nav-tabs .nav-item .nav-link:hover {
        border-right-color: #b8beca; }

@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

/* TOOLTIPS & POPOVERS */
.popover {
  border-color: #d2d9e5; }
  .popover .popover-inner {
    border-width: 0.66rem; }
    .popover .popover-inner:after {
      border-width: 0.58rem; }
  .popover.left:before, .popover.bs-tether-element-attached-left:before {
    border-right-color: #d2d9e5; }
  .popover.right:before, .popover.bs-tether-element-attached-right:before {
    border-left-color: #d2d9e5; }
  .popover.top:before, .popover.bs-tether-element-attached-top:before {
    border-bottom-color: #d2d9e5; }
  .popover.bottom:before, .popover.bs-tether-element-attached-bottom:before {
    border-top-color: #d2d9e5; }
  .popover .popover-title {
    font-weight: 600;
    background: #f2f4f8;
    margin: -1px;
    padding: 0.66rem 1.25rem;
    border-radius: 3px 3px 0 0; }

.tooltip .tooltip-inner {
  background: #222034;
  padding: 0.33rem 1.25rem;
  color: #fff;
  font-size: 1.08rem; }

.tooltip.left .tooltip-inner:before,
.tooltip.bs-tether-element-attached-left .tooltip-inner:before {
  border-right-color: #222034; }

.tooltip.right .tooltip-inner:before,
.tooltip.bs-tether-element-attached-right .tooltip-inner:before {
  border-left-color: #222034; }

.tooltip.top .tooltip-inner:before,
.tooltip.bs-tether-element-attached-top .tooltip-inner:before {
  border-bottom-color: #222034; }

.tooltip.bottom .tooltip-inner:before,
.tooltip.bs-tether-element-attached-bottom .tooltip-inner:before {
  border-top-color: #222034; }

.chartist-tooltip {
  position: absolute;
  display: none;
  opacity: 0;
  min-width: 5em;
  padding: 0.5em;
  background: #001529;
  color: #fff;
  font-weight: 700;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  transition: opacity 0.2s linear;
  border-radius: 2px; }

.chartist-tooltip:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -7px;
  border: 7px solid transparent;
  border-top-color: #001529; }

.chartist-tooltip.tooltip-show {
  display: inline-block;
  opacity: 1; }

.ct-area,
.ct-line {
  pointer-events: none; }

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.75rem;
  line-height: 1; }

.ct-chart {
  position: relative; }

.ct-tooltip {
  position: absolute;
  display: inline-block;
  min-width: 5em;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 4px 10px;
  background: #383838;
  color: #fff;
  text-align: center;
  pointer-events: none;
  z-index: 100;
  transform: translateY(13px);
  transition: opacity 0.2s linear; }

.ct-tooltip:before {
  position: absolute;
  bottom: -14px;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  pointer-events: none;
  border-color: rgba(251, 249, 228, 0);
  border-top-color: #383838;
  border-width: 7px;
  margin-left: -8px; }

.ct-tooltip.hide {
  display: block;
  opacity: 0;
  visibility: hidden; }

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }

.ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-label.ct-vertical.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end; }

.ct-label.ct-vertical.ct-end {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar .ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: start; }

.ct-chart-bar .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: end; }

.ct-grid {
  stroke: rgba(0, 0, 0, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px; }

.ct-point {
  stroke-width: 10px;
  stroke-linecap: round; }

.ct-line {
  fill: none;
  stroke-width: 4px; }

.ct-area {
  stroke: none;
  fill-opacity: 0.1; }

.ct-bar {
  fill: none;
  stroke-width: 10px; }

.ct-slice-donut {
  fill: none;
  stroke-width: 60px; }

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: #0190fe; }

.ct-series-a .ct-slice-pie,
.ct-series-a .ct-area {
  fill: #0190fe; }

.ct-series-b .ct-point,
.ct-series-b .ct-line,
.ct-series-b .ct-bar,
.ct-series-b .ct-slice-donut {
  stroke: #46be8a; }

.ct-series-b .ct-slice-pie,
.ct-series-b .ct-area {
  fill: #46be8a; }

.ct-series-c .ct-point,
.ct-series-c .ct-line,
.ct-series-c .ct-bar,
.ct-series-c .ct-slice-donut {
  stroke: #ff0; }

.ct-series-c .ct-slice-pie,
.ct-series-c .ct-area {
  fill: #ff0; }

.ct-series-d .ct-point,
.ct-series-d .ct-line,
.ct-series-d .ct-bar,
.ct-series-d .ct-slice-donut {
  stroke: #d17905; }

.ct-series-d .ct-slice-pie,
.ct-series-d .ct-area {
  fill: #d17905; }

.ct-series-e .ct-point,
.ct-series-e .ct-line,
.ct-series-e .ct-bar,
.ct-series-e .ct-slice-donut {
  stroke: #453d3f; }

.ct-series-e .ct-slice-pie,
.ct-series-e .ct-area {
  fill: #453d3f; }

.ct-series-f .ct-point,
.ct-series-f .ct-line,
.ct-series-f .ct-bar,
.ct-series-f .ct-slice-donut {
  stroke: #59922b; }

.ct-series-f .ct-slice-pie,
.ct-series-f .ct-area {
  fill: #59922b; }

.ct-series-g .ct-point,
.ct-series-g .ct-line,
.ct-series-g .ct-bar,
.ct-series-g .ct-slice-donut {
  stroke: #0544d3; }

.ct-series-g .ct-slice-pie,
.ct-series-g .ct-area {
  fill: #0544d3; }

.ct-series-h .ct-point,
.ct-series-h .ct-line,
.ct-series-h .ct-bar,
.ct-series-h .ct-slice-donut {
  stroke: #6b0392; }

.ct-series-h .ct-slice-pie,
.ct-series-h .ct-area {
  fill: #6b0392; }

.ct-series-i .ct-point,
.ct-series-i .ct-line,
.ct-series-i .ct-bar,
.ct-series-i .ct-slice-donut {
  stroke: #f05b4f; }

.ct-series-i .ct-slice-pie,
.ct-series-i .ct-area {
  fill: #f05b4f; }

.ct-series-j .ct-point,
.ct-series-j .ct-line,
.ct-series-j .ct-bar,
.ct-series-j .ct-slice-donut {
  stroke: #dda458; }

.ct-series-j .ct-slice-pie,
.ct-series-j .ct-area {
  fill: #dda458; }

.ct-series-k .ct-point,
.ct-series-k .ct-line,
.ct-series-k .ct-bar,
.ct-series-k .ct-slice-donut {
  stroke: #eacf7d; }

.ct-series-k .ct-slice-pie,
.ct-series-k .ct-area {
  fill: #eacf7d; }

.ct-series-l .ct-point,
.ct-series-l .ct-line,
.ct-series-l .ct-bar,
.ct-series-l .ct-slice-donut {
  stroke: #86797d; }

.ct-series-l .ct-slice-pie,
.ct-series-l .ct-area {
  fill: #86797d; }

.ct-series-m .ct-point,
.ct-series-m .ct-line,
.ct-series-m .ct-bar,
.ct-series-m .ct-slice-donut {
  stroke: #b2c326; }

.ct-series-m .ct-slice-pie,
.ct-series-m .ct-area {
  fill: #b2c326; }

.ct-series-n .ct-point,
.ct-series-n .ct-line,
.ct-series-n .ct-bar,
.ct-series-n .ct-slice-donut {
  stroke: #6188e2; }

.ct-series-n .ct-slice-pie,
.ct-series-n .ct-area {
  fill: #6188e2; }

.ct-series-o .ct-point,
.ct-series-o .ct-line,
.ct-series-o .ct-bar,
.ct-series-o .ct-slice-donut {
  stroke: #a748ca; }

.ct-series-o .ct-slice-pie,
.ct-series-o .ct-area {
  fill: #a748ca; }

.ct-square {
  display: block;
  position: relative;
  width: 100%; }

.ct-square:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 100%; }

.ct-square:after {
  content: '';
  display: table;
  clear: both; }

.ct-square > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-minor-second {
  display: block;
  position: relative;
  width: 100%; }

.ct-minor-second:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 93.75%; }

.ct-minor-second:after {
  content: '';
  display: table;
  clear: both; }

.ct-minor-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-second {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-second:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 88.8888888889%; }

.ct-major-second:after {
  content: '';
  display: table;
  clear: both; }

.ct-major-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-minor-third {
  display: block;
  position: relative;
  width: 100%; }

.ct-minor-third:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 83.3333333333%; }

.ct-minor-third:after {
  content: '';
  display: table;
  clear: both; }

.ct-minor-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-third {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-third:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 80%; }

.ct-major-third:after {
  content: '';
  display: table;
  clear: both; }

.ct-major-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-perfect-fourth {
  display: block;
  position: relative;
  width: 100%; }

.ct-perfect-fourth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 75%; }

.ct-perfect-fourth:after {
  content: '';
  display: table;
  clear: both; }

.ct-perfect-fourth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-perfect-fifth {
  display: block;
  position: relative;
  width: 100%; }

.ct-perfect-fifth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 66.6666666667%; }

.ct-perfect-fifth:after {
  content: '';
  display: table;
  clear: both; }

.ct-perfect-fifth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-minor-sixth {
  display: block;
  position: relative;
  width: 100%; }

.ct-minor-sixth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 62.5%; }

.ct-minor-sixth:after {
  content: '';
  display: table;
  clear: both; }

.ct-minor-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-golden-section {
  display: block;
  position: relative;
  width: 100%; }

.ct-golden-section:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 61.804697157%; }

.ct-golden-section:after {
  content: '';
  display: table;
  clear: both; }

.ct-golden-section > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-sixth {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-sixth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 60%; }

.ct-major-sixth:after {
  content: '';
  display: table;
  clear: both; }

.ct-major-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-minor-seventh {
  display: block;
  position: relative;
  width: 100%; }

.ct-minor-seventh:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 56.25%; }

.ct-minor-seventh:after {
  content: '';
  display: table;
  clear: both; }

.ct-minor-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-seventh {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-seventh:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 53.3333333333%; }

.ct-major-seventh:after {
  content: '';
  display: table;
  clear: both; }

.ct-major-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-octave {
  display: block;
  position: relative;
  width: 100%; }

.ct-octave:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 50%; }

.ct-octave:after {
  content: '';
  display: table;
  clear: both; }

.ct-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-tenth {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-tenth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 40%; }

.ct-major-tenth:after {
  content: '';
  display: table;
  clear: both; }

.ct-major-tenth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-eleventh {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-eleventh:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 37.5%; }

.ct-major-eleventh:after {
  content: '';
  display: table;
  clear: both; }

.ct-major-eleventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-twelfth {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-twelfth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 33.3333333333%; }

.ct-major-twelfth:after {
  content: '';
  display: table;
  clear: both; }

.ct-major-twelfth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-double-octave {
  display: block;
  position: relative;
  width: 100%; }

.ct-double-octave:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 25%; }

.ct-double-octave:after {
  content: '';
  display: table;
  clear: both; }

.ct-double-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

/*# sourceMappingURL=chartist.css.map */
@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

/*  NPROGRESS */
#nprogress {
  pointer-events: none; }

#nprogress .bar {
  height: 3px;
  background: #4b58dd;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%; }

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0, -4px);
  -ms-transform: rotate(3deg) translate(0, -4px);
  transform: rotate(3deg) translate(0, -4px); }

#nprogress .spinner {
  display: none;
  position: fixed;
  z-index: 1031;
  width: 11.66rem;
  padding: 0.66rem 1.25rem;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #e4e9f0;
  right: auto;
  left: 50%;
  top: 1.08rem;
  margin-left: -6.09rem; }
  #nprogress .spinner:after {
    content: 'Loading...';
    display: inline-block;
    position: absolute;
    top: 0.58rem;
    left: 4rem; }

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #4b58dd;
  border-left-color: #4b58dd;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite; }

.nprogress-custom-parent {
  overflow: hidden;
  position: relative; }

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute; }

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

/*  RCDRAWER */
.drawer-handle {
  background: #1260E6 !important;
  top: 104px;
  transition: background 0.3s; }
  .drawer-handle:hover {
    background: #7583eb !important; }

.drawer.drawer-open .drawer-handle-icon {
  background: transparent !important; }

.drawer-handle-icon {
  background: #fff !important; }
  .drawer-handle-icon:after, .drawer-handle-icon:before {
    background: #fff !important; }

.drawer .drawer-content {
  overflow: visible;
  background: #edeff3;
  transition: background 0.3s; }

.drawer.drawer-light .drawer-content {
  background: #fff; }

.drawer-content-wrapper {
  box-shadow: none !important; }

@media screen and (max-width: 767px) {
  html {
    font-size: 10px; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 9px; } }

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px; } }

:export {
  linearToColor: #4738c3;
  linearFromColor: #d71658;
  purpleDark: #1260E6;
  warningColor: #FE7F16;
  successColor: #46be8a;
  errorColor: #e1176b;
  disabled: #9e9e9e;
  collapseDelay: 250ms; }

.ant-carousel .slick-dots-bottom {
  bottom: -16px !important;
  margin-top: 10px !important; }

.ant-carousel .slick-dots li button {
  width: 6px !important;
  height: 6px !important;
  border-radius: 100% !important;
  background: #E0E0E0; }

.slick-dots li.slick-active button {
  width: 7px !important;
  height: 7px !important;
  border-radius: 100% !important;
  background: #1260E6 !important; }

.ant-progress-bg {
  height: 14px !important; }

.ant-progress-text {
  display: none; }

.ant-progress-outer {
  padding: 0 !important; }

.ant-layout {
  min-height: 100% !important; }

.continue_button {
  font-weight: bold !important;
  border-radius: 8px !important;
  font-size: 18px !important;
  font-family: "Work Sans", sans-serif !important !important; }

/*
 * The body's overflow is fixed to the initial value to avoid unwanted page scrolling
 * when a modal is mounted on the screen
 */
body {
  overflow: initial !important; }

:root {
  --height_subscriptions_actions: 150px; }

@media screen and (min-width: 768px) {
  :root {
    --height_subscriptions_actions: 80px; } }

#root {
  min-height: 100%;
  height: 0; }
