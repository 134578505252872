@import 'assets/styles/mixins.scss';

.selector__container {
  
  margin-right: auto;
  line-height: 1;
  margin: 0 auto 0 48px;
  .project__name {
    color: $text;
    font-family: $base__font-family;
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;

  }

  .project__url {
    color: $purple-dark;
    font-size: 1.4rem;

    &:hover {
      text-decoration: underline;
    }
  }
}
