@import 'assets/styles/mixins.scss';

.checkbox_container {
  display: block;
  position: relative;
  & label {
    &:after {
      width: 20px;
      height: 20px;
      content: '';
      border: 2px solid $black;
      background-color: $gray-50;
      background-image: url("../../../assets/images/icons/check-square.svg");
      background-size: 0;
      background-repeat: no-repeat;
      z-index: 2;
      position: absolute;
      right: 50%;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 4px;
      cursor: pointer;
    }
  }
  & .checkbox:checked ~ label {
    &:after {
      width: 24px;
      height: 24px;
      background-color: $gray-50;
      border: 0px;
      background-size: auto;
    }
    & .checkbox:disabled ~ label {
      &:after {
        background-image: url("../../../assets/images/icons/check-circle-disabled.svg");
      }
    }
  }

  & .checkbox:disabled ~ label {
    &:after {
      border-color: $gray-500;
      background-image: url("../../../assets/images/icons/check-circle-disabled.svg");
      cursor: auto;
    }
  }

  & .checkbox {
    width: 24px;
    height: 24px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }

  &.small {
    & label {
      &:after {
        width: 20px;
        height: 20px;
      }}
   
    .checkbox:checked ~ label {
      &:after {
        background-size: contain;
        width: 22px;
        height: 22px
      }
    }
  }
}
