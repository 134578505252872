@import 'assets/styles/mixins.scss';

.modal_button {
  flex: 1;
}

.highlight_text {
  color: $purple-dark;
}

