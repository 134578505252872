@import 'assets/styles/mixins.scss';

.alert_container {
  font-size: 14px;
  display: flex;
  width: 100%;
}

.alert_background {
  background: $gray-50;
  padding: 24px 32px;
  align-items: center;
  border: 1px solid $gray-50;
  border-radius: 8px;
}

.alert_error {
  color: $danger-dark;
}

.alert_info {
  color: $gray-700;
}

.alert_warning {
  color: $warning;
}

.alert_text {
  margin-bottom: 0;
  font-style: italic;
  line-height: 16px;
  letter-spacing: normal;
  font-family: $base__font-family;
}

.alert_text_container {
  margin-left: 8px;
}
