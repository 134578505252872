@import 'assets/styles/mixins.scss';

.coupon_container {
  padding: 32px;
  border: 1px solid $gray-100;
  border-radius: 8px;

  .title {
    font-size: 18px;
    line-height: 25px;
    font-family: $title__font-family;
    font-weight: $semibold__font-weight;
  }

  .description {
    font-size: 16px;
    line-height: 19px;
    color: $purple;
    margin-top: 12px;
  }

  .input {
    margin-top: 40px;
    max-width: 600px;
    margin-bottom: 8px;

    &_error {
      @extend .input;
      input {
        border-color: $danger-dark;
      }
    }

    input {
      border-radius: 8px;

      &:disabled {
        background-color: $white;
      }
    }
  }

  .buttons_container {
    display: flex;
    gap: 8px;
  }
}

.info_danger_message {
  margin-bottom: 8px;
}
