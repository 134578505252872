@import 'assets/styles/mixins.scss';

.card_radio_button_container {
  display: flex;
  align-items: center;
  position: relative;
  padding: 18px 16px;
  background: $gray-50;
  border-radius: 8px;
  gap: 12px;
  cursor: pointer;
  @include transition-middle();

  &:hover, &:active, &:focus {
    box-shadow: 0 0 0 1px $purple-dark;

    & label:after {
      border-color: $purple-dark;
    }
  }

  & .input{
    margin-left: 24px;
  }

  & p{
    margin-bottom: 0px;
    display: inline;
  }

  & .title {
    display: flex;
    flex-direction: column;

    & p{
      margin-bottom: 0px;
      display: inline;
      font-size: 18px;
      font-family: $base__font-family;
      color: $black-dark;
      line-height: 21px;
    }
  }

  &.active {
    box-shadow: 0 0 0 1px $purple-dark;

    & .title p {
      color: $purple-dark;
    }
  }

  &.disabled {
    cursor: auto;
    box-shadow: none;
    & .title p {
      color: $gray-500;
    }
    label {
      &:after {
        border-color: $gray-500;
      }

    }
  }

  & .badge {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-50%);
    border-radius: 12px;
    background: $default_gradient;
    font-size: 12px;
    color: $white;
    font-weight: bold;
    padding: 0 12px;
  }
}
