.select_phone_code {
  input, div:last-child {
    border-top-left-radius: 8px!important;
    border-bottom-left-radius: 8px!important;
  }
}

.group_phone {
  :global(.ant-input)  {
    border-top-right-radius: 8px!important;
    border-bottom-right-radius: 8px!important;
  }
}