@import 'assets/styles/mixins.scss';

.advanced_configuration_wrapper {
  border: 1px solid $gray-100;
  padding: 32px;
  border-radius: 8px;

  &_modal {
    @extend .advanced_configuration_wrapper;
    max-width: 850px;
    margin: 0 auto;
  }

  & > h2 {
    font-family: $title__font-family;
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }
  & > p {
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 12px;
    font-family: $base__font-family;
  }

  & .configuration_container {
    display: flex;
    gap: 30px;
    margin-top: 40px;
    flex-wrap: wrap;
  }

  .definitions_container {
    margin-top: 40px;
  }
}

.button_container {
  display: flex;
  justify-content: center;
  grid-gap: 32px;
  gap: 32px;
  position: sticky;
  font-size: 18px;
  line-height: 21px;
  padding: 12px 0;

  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;
  z-index: 2;
  border-top: 1px solid $gray-100;
  button {
    font-weight: bold !important;
    width: 226px;
  }
}

.select_all_wrapper {
  padding-top: 40px;
}

.modal_button {
  flex: 1;
}

.advice_message_wrapper {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
