@import 'assets/styles/mixins.scss';

.languages_container {
  margin-top: 32px;

  & .languages {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 16px;
  }
}

.not_available {
  font-style: italic;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #9e9e9e !important;
}

.languages_container h1 {
  font-size: 16px;
  font-weight: bold;
  font-family: $base__font-family;
  line-height: 19px;
  color: $gray-700;
}

.languages_container p {
  font-size: 16px;
  line-height: 19px;
  font-family: $base__font-family;
  color: $purple;
}

.cardRadioContainer {
  min-height: 56px;
  padding: 0 16px;
}