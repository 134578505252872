@mixin icon_button_size($icon-size, $text-size) {
  svg {
    width: $icon-size;
    height: $icon-size;
  }
  .spin, :global(.ant-spin-dot) {
    font-size: $icon-size;
  }
  span {
    font-size: $text-size;
  }
}

.icon_button_small {
  @include icon_button_size(20px, 14px);
}

.icon_button_medium {
  @include icon_button_size(20px, 16px);
}

.icon_button_large {
  @include icon_button_size(24px, 18px);
}

.only_icon {
  border-radius: 50%;
}
