@import '../confirmModal/index.module';

.modal_button {
  flex: 1;
}

.underline_text {
  text-decoration: underline !important;
}

.loading_container {
  text-align: center;
  padding: 26px;

  .loading_title {
    font-size: 24px;
    line-height: 30px;
    font-family: $title__font-family;
    font-weight: bold;
    margin-top: 12px;
  }

  .loading_description {
    font-size: 16px;
    line-height: 22px;
    color: $purple;
    margin: 12px 0 0 0;
  }
}
