@import 'assets/styles/mixins.scss';

.menu {
  box-sizing: content-box;
  position: relative;

  :global(.ant-menu-light) {
    &:before,
    &:after {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      background: $gray-darken;
      opacity: 0.4;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:after {
      left: auto;
      right: 0;
    }

    :global(.settings__menuShadow) & {
      &:before,
      &:after {
        display: none;
      }
    }

    @media (max-width: 1435px) {
      :global(.settings__fixedWidth) & {
        &:before {
          display: none;
        }
      }
    }
  }

  :global(.ant-layout-sider-children) {
    position: fixed;
    height: 100%;
    width: 256px;
    transition: width 0.2s;
  }

  &:global(.ant-layout-sider-collapsed) {
    max-width: 64px !important;
    min-width: 64px !important;
    width: 64px !important;
    flex: 0 0 64px !important;

    :global(.ant-layout-sider-children) {
      width: 64px;
    }

    :global(.ant-layout-sider-trigger) {
      width: 64px !important;
    }
  }

  :global(.ant-menu-inline-collapsed-tooltip) .icon {
    display: none;
  }

  :global(.ant-layout-sider-zero-width-trigger) {
    top: 10px !important;
  }

  :global(.ant-menu-item),
  :global(.ant-menu-submenu-title) {
    transition: padding 0s !important;
  }
}

.logo {
  height: 80px;
  background: $menuBg-darken;
  overflow: hidden;
}

.logoContainer {
  height: 80px;
  padding: 13px 20px 15px 22px;
  background-color: $menuBg;

  display: flex;
  align-items: center;
  transition: padding 200ms ease-out;

  img {
    height: 24px;
  }

  &.logoCollapsed {
    padding: 0;
    padding-left: 9px;

    img {
      width: 44px;
    }
  }
}

.icon {
  margin-right: 20px;
  color: $text;
  display: inline-block;
  vertical-align: -3px;
}

#menu_item_selected {
  background-color: #f8f8f8;
}

.navigation {
  border: none;

  > :global(.ant-menu-item-divider) {
    margin: 0 23px !important;
  }

  :global(.ant-menu-submenu-inline) > :global(.ant-menu-submenu-title .ant-menu-submenu-arrow) {
    right: 50px;

    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  :global(.ant-menu-inline, .ant-menu-vertical),
  :global(.ant-menu-vertical-left) {
    border-right: none;
  }

  :global(.ant-menu-dark .ant-menu-inline.ant-menu-sub) {
    box-shadow: none !important;
    transition: all 0.3s;
  }

  &:global(.ant-menu-inline-collapsed) {
    width: 64px;

    .title {
      display: none;
    }

    :global(.ant-menu-submenu) > :global(.ant-menu-submenu-title .ant-menu-submenu-arrow) {
      display: block;
      opacity: 0.2;
      top: 48%;
      right: 14px;
    }

    .icon {
      right: 30px;
    }
  }

  &:global(.ant-menu-light) {
    .icon {
      color: $gray-darken;
      @include transition-slow();
    }

    :global(.ant-menu-submenu-arrow) {
      opacity: 0.4;
    }

    :global(.ant-menu-item:after) {
      right: auto;
      left: 0;
    }

    :global(.ant-menu-item),
    :global(.ant-menu-submenu) {
      &:hover {
        .icon {
          color: $primary;
        }
      }
    }

    :global(.ant-menu-item-selected),
    :global(.ant-menu-submenu-selected) {
      .icon {
        color: $primary;
      }
    }

    :global(.ant-menu-item),
    :global(.ant-menu-submenu-title) {
      width: 100%;
    }
  }

  &:global(.ant-menu-dark) {
    color: $gray-darken;

    :global(.ant-menu-item-divider) {
      opacity: 0.2;
    }

    :global(.ant-menu-sub) {
      color: $gray-darken;
    }

    :global(.ant-menu-item) {
      color: $gray-darken;
      > a {
        color: $gray-darken;
        &:hover {
          .icon {
            color: $white !important;
          }
        }
      }
    }

    :global(.ant-menu-item-selected) {
      background-color: $white;
      > a {
        color: $primary;

        .icon {
          color: $primary !important;
        }
      }
    }

    :global(.ant-menu-submenu:hover),
    :global(.ant-menu-submenu-selected),
    :global(.ant-menu-submenu-open) {
      .icon {
        color: $white !important;
      }
    }

    :global(.ant-menu-submenu-active) {
      .icon {
        color: $blue !important;
      }
    }

    :global(.ant-menu-inline.ant-menu-sub) {
      box-shadow: none !important;
      transition: all 0.3s;
    }

    :global(.ant-menu-item:after) {
      border-right: 3px solid $white !important;
      right: auto;
      left: 0;
    }
  }
}

.scrollbarDesktop {
  height: calc(100vh - 128px) !important;
  background-color: $menuBg;
  scrollbar-width: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  & > div:first-child {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: 20px;
  }
}

.scrollbarMobile {
  height: calc(100vh - #{$top_bar-height}) !important;
  background-color: $menuBg;
  scrollbar-width: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  & > div:first-child {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: 20px;
  }
}

.light {
  background: $white;

  :global(.ant-layout-sider-trigger) {
    color: inherit;
    background: $gray-border;
  }

  :global(.ant-menu-item) > :global(a) {
    color: $text;
    &:hover {
      color: $primary;
    }
  }

  :global(.ant-menu-item-selected) > :global(a) {
    color: $primary;
  }

  :global(.drawer-content) {
    background: $white !important;
  }
}

:global(.ant-menu-inline-collapsed-tooltip) {
  :global(.icon-collapsed-hidden) {
    display: none;
  }
}

:global(.ant-menu-inline-collapsed) {
  :global(.badge-collapsed-hidden) {
    display: none;
  }
}

:global(.ant-menu-inline-collapsed) + .buyPro {
  display: none;
}

.logoText {
  color: #e4e9f0;
}
