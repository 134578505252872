@import 'assets/styles/mixins.scss';

// ANT DESIGN TABLE
.ant-table-thead > tr > th {
  background-color: transparent;
  border-bottom-width: 2px;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: $gray-50 !important;
}

// filter dropdown (column)
.ant-table-filter-dropdown {
  margin-left: -20px;

  ul > li {
    padding: 0.8rem;
  }

  // ok and cancel dropdown buttons
  .ant-table-filter-dropdown-btns {
    & :first-child {
      margin-right: 15px;
    }
  }
}
