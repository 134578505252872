@import 'assets/styles/mixins.scss';

.modal_container {
  text-align: left;
  padding-top: 40px;
}

.step {
  header {
    max-width: 80rem!important;
  }
}

.root {
  width: 100%;
  max-width: 100ch;
  margin: 0 auto;
  .imageLayout {
    .successImage {
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }
    margin-bottom: 40px;
  }
  .infoLayout {
    text-align: center;
    .title {
      font-family: $title__font-family;
      margin-bottom: 12px;
      font-size: 24px;
      font-weight: bold;
      line-height: 30px;
    }
    .description {
      font-family: $base__font-family;
      color: $purple;
      font-size: 18px;
      line-height: 24px;
      high {
          color: $purple-dark;
      }
    }
    
  }

}

.button_wrapper {
  padding-top: 48px;
  text-align: center;
  
}
.close_button {
  min-width: 256px;
}