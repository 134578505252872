@import 'assets/styles/mixins.scss';

.icon_button_close {
  position: absolute;
  top: -32px;
  right: 0;
  width: auto!important;
  svg {
    width: 32px;
    height: 32px;
  }
}

.modal_container {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  width: 100%;
  height: calc(100vh - #{$top_bar-height});
  background-color: $white;
  animation: myAnim .3s linear;
  margin-top: $top_bar-height;
  overflow: auto;
  &.isFullHeight {
    height: 100vh;
    margin-top: 0
  }
}

@keyframes myAnim {
  0% {
    animation-timing-function: ease-in;
    transform: translateY(100%);
  }

  100% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }
}

.modal_content {
  margin-top: 36px;
  position: relative;
}
