// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN MODAL */

.ant-modal-close {
  outline: none !important;
}

.ant-modal-confirm.ant-modal-confirm-confirm {
  .ant-modal-body {
    padding: 0;
    padding-top: 32px;

    i {
      display: none;
    }
  }

  .ant-modal-confirm-body {
    padding: 12px 24px;
    text-align: center;

    .ant-modal-confirm-content {
      margin-left: 0;
    }
  }

  .ant-modal-confirm-btns {
    float: none;
    width: 100%;
    display: flex;

    button {
      flex: 1;
      margin: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      &:not(.ant-btn-primary) {
        border-bottom-right-radius: 0;
      }

      &.ant-btn-primary {
        border-bottom-left-radius: 0;
      }
    }
  }
}

.ant-modal.welcome-modal {
  width: 600px !important;

  .ant-modal-content {
    .ant-modal-header {
      border-bottom: 0;

      .ant-modal-title {
        font-size: 2rem;
      }
    }

    .ant-modal-body {
      font-size: 1.4rem;
    }

    .ant-modal-footer {
      border-top: 0;
    }
  }
}
