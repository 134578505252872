@import 'assets/styles/mixins.scss';

.ant-btn {
  border-radius: 8px; // before:2px => now:8px (new desing glotio)
  height: $form__item-height;
  font-weight: 500;
  background-color: #e8e8e8;
  color: #8f8f8f;

  padding: 0 45px;
  font-size: 1.5rem;

  &:hover {
    border-color: transparent;
  }

  &:not(.ant-btn-primary):not(.ant-btn-danger):not(.ant-btn-dashed):not(.ant-btn-link):hover,
  &:not(.ant-btn-primary):not(.ant-btn-danger):not(.ant-btn-dashed):not(.ant-btn-link):focus,
  &:not(.ant-btn-primary):not(.ant-btn-danger):not(.ant-btn-dashed):not(.ant-btn-link):active {
    background-color: rgba($gray, 0.7);
  }

  &[disabled],
  &[disabled]:hover {
    color: $white;
    background-color: rgba($gray, 0.7);
  }

  &.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
    padding-left: 45px;
  }
}

.ant-btn-primary {
  background-color: $primary;
  color: white;
}

.ant-btn-danger {
  background-color: $red;
  color: white;
}

.ant-btn-dashed  {
  background-color: #fff;
}

.ant-btn-link {
  background-color: #fff;
  color: $primary;
  padding: 0;
}
