@import 'assets/styles/mixins.scss';

.dropdown {
  padding-right: 15px;
  position: relative;
  cursor: pointer;
  .dropdown_wrapper_icon {
    color: $purple-dark;
    display: flex;
    align-items: center;
    &.isVisible {
      color: $purple;
    }
  }
  &:hover {
    .dropdown_wrapper_icon {
      color: $purple;
    }
  }
  .icon_action {
    width: 24px;
    height: 24px;
  }
}

.custom__button {
  appearance: none;
  border: none;
  background: none;
  width: 100%;
  padding: 1rem 1.66rem;
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  .icon_text {
    font-family: $base__font-family;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
  }
}
.menu_item {
  padding: 0!important;
  color: $purple-dark;
  &:hover {
    color: $purple;
  }
  .menu_icon {
    width: 20px;
    height: 20px;
  }
}