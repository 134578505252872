@import 'assets/styles/mixins.scss';

.auth_promotion_block {
  border-radius: 8px;
  overflow: hidden;
  color: $white;
  background: $default_gradient;
  padding: 24px;
  width: 100%;
  max-width: 520px;
  margin-top: 40px;
  display: block;
  flex: none;
  .title {
    font-size: 20px;
    color: $white;
    font-weight: $semibold__font-weight;
    line-height: 22px;
    font-family: $base__font-family;
    text-align: left;
    padding-left: rem(20);
    
  }
  .left_content {
    flex: 0.60;
    margin-bottom: 16px;
  }
  .right_content {
    flex: 0.40;
    font-size: 14px;
    line-height: 16px;
  }
  @media screen and (min-width: $xs-min-width) {
    display: flex;
    align-items: center;
    .left_content {
      flex: 0.60;
      margin-right: 16px;
      margin-bottom: 0;
    }
  }

}