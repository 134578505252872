@import 'assets/styles/mixins.scss';


.wrapper_title {
  padding: 16px;
  .title {
    font-size: 24px;
    line-height: 30px;
    color: $black-dark;
    text-align: center;
    font-family: $title__font-family;
    font-weight: bold;
  }
  .description {
    font-size: 16px;
    line-height: 22px;
    color: $purple;
    text-align: center;
    font-family: $base__font-family;
  }
}

.wrapper_content {
    .textarea_wrapper {
      margin-top: 12px;
      .textarea {
        border-radius: 8px!important;
      }
    }
    .question_block {
      display: flex;
      padding: 18px 16px;
      background: $gray-50;
      border-radius: 8px;
      margin-top: 8px;
    }

    .active_question {
      border: 1px solid $purple-dark;
    }

    .question_input {
      margin-left: 32px;
    }

    .question_title {
      margin-left: 16px;
      font-family: $base__font-family;
      font-size: 18px;
      color: $black-dark;
      line-height: 21px;

      & p{
        margin-bottom: 0px;
        display: inline;
      }
    }

    .active_question_title {
      color: $purple-dark;
    }

    .label_question {
      width: 100%;
      cursor: pointer;
    }
}

.wrapper_actions {
    padding: 16px;
    display: flex;
    gap: 16px;
    margin: 0 auto;
    width: 100%;
    max-width: 550px;
    flex-wrap: wrap;
    .modal_button {
      flex: 1;
    }
}
