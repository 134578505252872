@import 'assets/styles/mixins.scss';

.assistant_container{
  display: flex;
}

.content_block_step{
  width: 100%;
  text-align: center;
}

.spin_block{
  padding-top: 2rem;
  color: $spinner-color;
}

.synchronization_icon_error{
  font-size: 1.5rem;
  color: $spinner-error-color;
}

.synchronization_text_error{
  font-size: 1.5rem;
  margin-left: 1rem;
  color: $spinner-error-color;
}

