@import 'assets/styles/mixins.scss';

.faq_wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.faq_button_container {
  display: flex;
  justify-content: space-between;
  border: 1px solid $purple-dark;
  padding: 10px 16px;
  font-size: 16px;
  line-height: 19px;

  .link_icon {
    width: 20px;
    height: 20px;
  }

  &:hover {
    border-color: $purple;
  }
}

.faq_actions {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  gap: 24px;

  .action {
    background-color: $gray-50;
  }
}
