@import 'assets/styles/mixins.scss';

.title {
  font-size: 24px;
  color: $white;
  font-weight: $semibold__font-weight;
  line-height: 22px;
  margin: 0;
}

.subtitle {
  font-size: 14px;
  line-height: 16px;
  margin: 16px 0 0 0;
}

.title, .subtitle {
  font-family: $base__font-family;
}
