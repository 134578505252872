@import 'assets/styles/mixins.scss';

.saved_method_container {
  max-width: 70rem;
  display: flex;
  padding: 17px 16px;
  background-color: $gray-50;
  border-radius: 8px;
  gap: 48px;
}

.skeleton {
  max-width: 80rem;
}

// Edit mode
.card_input {
  max-width: 60rem;
}

.action_buttons {
  margin-top: 1rem;

  .button_cancel {
    margin-right: 1rem;
  }
}

// Popover alert to enter billing data before payment method
:global(.ant-popover-buttons) {
  display: none !important;
}

:global(.ant-popover-message) {
  font-size: 1.5rem;
  max-width: 450px;
}

.alert {
  max-width: 80rem;
}

.retry_payment_button{
  margin-top: 8px;
}
