@import 'assets/styles/mixins.scss';

.lang_resume {
  margin-bottom: 32px;

  h2 {
    margin-bottom: 16px;
  }

  table {
    width: 100%;
    font-size: 1.4rem;

    thead {
      font-weight: 600;
      text-transform: capitalize;
      border-bottom: 2px solid $gray;

      td {
        padding: 8px 0;
      }
    }

    tr {
      td {
        padding-top: 8px;
      }
    }
  }
}

.error_msg {
  font-size: 1.5rem;
  margin-bottom: 0;
}
