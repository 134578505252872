.ant-carousel .slick-dots-bottom {
  bottom: -16px !important;
  margin-top: 10px !important;
}

.ant-carousel .slick-dots li button {
  width: 6px !important;
  height: 6px !important;
  border-radius: 100% !important;
  background: $gray-300;
}
.slick-dots li.slick-active button {
  width: 7px !important;
  height: 7px !important;
  border-radius: 100% !important;
  background: $purple-dark !important;
}
