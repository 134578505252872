@import 'assets/styles/mixins.scss';

.ant-radio-group-large {
  .ant-radio-button-wrapper {
    white-space: nowrap;
    height: 49px;
    margin: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    border-width: 1.5px;
    border-left: 1.5px solid transparent;
    border-color: $gray;
    box-shadow: none;

    &::before {
      content: none;
    }

    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-color: $primary;
    }
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
      box-shadow: none;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
