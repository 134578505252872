@import 'assets/styles/mixins.scss';

.edit_view_wrapper {
  background-color: $gray-50;
  border-radius: 8px;
  margin-top: 30px;
  padding: 16px;
  display: flex;
  max-width: 70rem;
  .content {
    flex: 1;
    p {
      color: $text-span;
      font-family: $base__font-family;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 19px;
      span {
        color: $purple-dark;
      }
    }
  }
  .action {
    flex-shrink: 0;
  }
}