@import 'assets/styles/mixins.scss';

.definition_header {
    padding: 12px 0;
  .definition_title{
    color: $gray-700;
    font-family: $base__font-family;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    margin-right: 8px;
  }

  .definition_title_info {
    height: 16px;
    width: 119px;
    color: $gray-700;
    font-family: $base__font-family;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 16px;
  }
}

.definition_block {
  margin-bottom: 8px;
}

.defintion_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.defintion_category_wrapper {
  margin-bottom: 40px;
}

.not_supported {
  color: $danger;
}
