@import 'assets/styles/mixins.scss';

.container_btn {
  display: flex;
  justify-content: center;

  button {
    min-width: 220px;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .wizard_description_container {
  .wizard_description_text{
      font-size: 16px;
      margin-bottom: 8px;
      font-weight: normal;
    }
    .wizard_description_subtitle {
      display: flex;
      gap: 8px;
      align-items: center;
      svg {
        color:  $gray-700;
        width: 16px;
        height: 16px;
      }
      i {
        color: $gray-700;
        margin: 0;
        font-size: 14px;
        line-height: 16px;
      }
    }

  }
  @media (max-width: $sm-max-width) {
   flex-direction: column;
    gap: 12px;
  }
}

.footer_container {
  display: flex;
  width: 100%;
  justify-content: center;
}
