@import 'assets/styles/mixins.scss';

.language_info_table {
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-family: $base__font-family;
    .header {
      display: flex;
      span {
        flex: 1;
        padding: 0 16px;
        color: $text-span;
        font-size: 14px;
        font-size: 16px;
      }
    }
    .row {
      display: flex;
      background-color: #FAFAFA;
      border-radius: 8px;
      word-break: break-all;
      
      .col {
        flex: 1;
        padding: 18px 16px;
        font-size: 18px;
        line-height: 19px;
        color: $text-span;
        &.highlight {
          color: $purple-dark;
          font-size: 18px;
          line-height: 21px;
        }
      }
    }
  }
  
  .alignment_right {
    text-align: right;
  }
  .alignment_center {
    text-align: center;
  }
  .alignment_left {
    text-align: left;
  }