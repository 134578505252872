// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN INPUT */

$input-border: 1px;
$focus-border: 1.5px;

.ant-input-disabled {
  background: $gray-lighten-more-more;
}

%base-input {
  transition: border 300ms ease-in-out;
  height: $form__item-height;
  border-width: $focus-border;
  border-radius: 8px;
  font-size: 1.4rem;
}

.ant-input {
  @extend %base-input;

  &:hover {
    border-width: 1.5px;
    border-right-width: $focus-border !important;
    border-color: $primary;
  }

  &:focus {
    border-width: 1.5px;
    border-right-width: $focus-border !important;
  }
}

.ant-input-affix-wrapper {
  @extend %base-input;

  &:hover .ant-input:not(.ant-input-disabled) {
    border-color: $primary;
    border-right-width: $focus-border !important;
  }
}

.ant-input-number {
  height: $form__item-height;
  border-width: $focus-border;
  border-radius: 2px;

  .ant-input-number-input-wrap {
    height: $form__item-height;
    display: flex;
    align-items: center;
  }

  &:hover,
  &.ant-input-number-focused {
    border-width: 1.5px;
    border-right-width: $focus-border !important;
    border-color: $primary;
  }

  &:hover .ant-input-number-handler-wrap {
    opacity: 0;
    display: none;
  }
}

.has-error .ant-input:focus,
.has-error .ant-input:hover,
.has-error .ant-input:not([disabled]):hover {
  border-width: 1.5px;
  border-right-width: $focus-border !important;
  border-color: $danger;
}

.has-error .ant-form-explain,
.has-error .ant-form-split {
  margin-top: 5px;
}
