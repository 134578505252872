@import 'assets/styles/mixins.scss';

.step_header {
  .step_header_content {
    display: flex;
    width: 100%;
    gap: 16px;
    align-items: center;
    margin-bottom: 12px;
  }

  .step_title_content {
    width: 100%;
  }

  .step_title {
    font-family: $title__font-family;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0;
    color: $black-dark;
  }

  .step_subtitle {
    font-size: 1.5rem;
    font-family: $base__font-family;
    font-weight: normal;
    color: $purple;
  }
}

.step_body {
  margin-top: 0;
  font-size: 1.3rem;
}

.step_content {
  width: 100%;
  padding: 32px 36px 32px 36px;

  &_full {
    @extend .step_content;
    padding: 0;
  }
}

.step_content_border {
  border: 1px solid $gray-100;
  border-radius: 8px;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -135%);
  position: absolute;
  border-radius: 50%;
  width: 37px;
  height: 37px;
  padding: 10px;
  background: $gray-100;
  border: 3px solid $gray-100;
  font-family: $title__font-family;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}
