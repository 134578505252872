@import './mixins.scss';
@import './Carousel/carousel.scss';
@import './ProgressBar/progressbar.scss';
@import './ContinueButton/continueButton.scss';

/*
 * The body's overflow is fixed to the initial value to avoid unwanted page scrolling
 * when a modal is mounted on the screen
 */
body {
  overflow: initial !important;
}

:root {
  --height_subscriptions_actions: 150px;
}

@media screen and (min-width: $sm-min-width) {
  :root { 
    --height_subscriptions_actions: 80px;
  }
}