@import '../../../assets/styles/mixins.scss';

.statusProgressBar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  bottom: 18px;
  right: 100px;
  font-size: 1.2rem;

  .process_info {
    display: flex;
    width: 100%;
    flex-direction: row;

    &_title {
      font-weight: 600;
      font-size: 1.4rem;
      text-transform: capitalize;
    }

    &_processed {
      color: $text;
      font-size: 12px;
      margin-bottom: 0;
    }

    &_percentage {
      color: $primary;
      display: inline-block;
      margin-bottom: 0;
      margin-right: 5px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .progress_bar {
    width: 100%;
  }
}

.statusInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
