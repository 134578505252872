@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN SELECT */

.ant-switch {
  height: 14px;
  min-width: 38px;

  &::after {
    left: -5px;
    top: -3px;
    border: 1px solid rgba($gray, 0.4);
    box-shadow: 0 2px 4px 0 rgba($text, 0.6);
  }
}

.ant-switch-small {
  height: 11px;
  min-width: 25px;

  &::after {
    width: 16px;
    height: 16px;
    top: -3px;
    left: -5px;
  }
}

.ant-switch-checked {
  background-color: rgba($primary, 0.4);

  &::after {
    background-color: $primary;
    left: 110%;
  }
}

.ant-switch-small.ant-switch-checked {
  background-color: rgba($primary, 0.4);

  &::after {
    left: 90%;
    background-color: $primary;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
