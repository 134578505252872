@import 'assets/styles/mixins.scss';

.languages_container {
  margin-top: 32px;

  & .languages {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 16px;
  }
}

.languages_container h1 {
  font-size: 16px;
  font-weight: bold;
  font-family: $title__font-family;
  line-height: 19px;
  color: $gray-700;
}

.languages_container p {
  font-size: 16px;
  line-height: 19px;
  font-family: $base__font-family;
  color: $purple;
}

.free_first_language {
  color: $purple-dark;
  display: inline-block;
  font-weight: bold;
}

.infoAdvertisement_spacer {
  margin-top: 16px;
}
.infoAdvertisement_percent {
  font-size: 30px;
}

.not_available {
  font-style: italic;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #9e9e9e !important;
}

.content_wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  &.isReverse {
    flex-direction: column-reverse;
  }
}

.modal_button {
  flex: 1;
}

.h2_lg {
  > h2 {
    line-height: $banner_lh;
  }
}

.container_resume {
  background-color: $gray-50;
  padding: 32px;
  border-radius: 8px;
  margin-top: 0;
}
