@import 'assets/styles/mixins.scss';

.info_advertisement_container {
    padding: 24px;
    border-radius: 8px;
    color: $white;
    background: $default_gradient;
    &_pointer {
      @extend .info_advertisement_container;
      cursor: pointer;
    }
  }
