@import '../../mixins.scss';

.ant-alert {
  font-size: 1.4rem;
  line-height: 1.6;
  border: none;
  padding: 15px 30px 15px 40px;

  a {
    color: inherit;
    font-weight: 600;
    text-decoration: underline;
  }

  &.ant-alert-no-icon {
    padding: 15px 30px;
  }

  .ant-alert-icon {
    top: 17.75px;
  }

  .ant-alert-message {
    margin-bottom: 0;

    * {
      margin-bottom: 0;
    }
  }

  .ant-alert-description {
    margin: 0 !important;
  }
}

.ant-alert-success {
  .ant-alert-message {
    color: var(--green);
  }
}

.ant-alert-info {
  .ant-alert-message {
    color: var(--blue);
  }
}

.ant-alert-warning {
  .ant-alert-message {
    color: var(--orange);
  }
}

.ant-alert-error {
  .ant-alert-message {
    color: var(--red);
  }
}

.ant-alert-close-icon {
  top: 15px;
}
