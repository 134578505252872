@import 'assets/styles/mixins.scss';

.card_button {
  flex: 1;
  padding: 16px;
  border-radius: 8px;
  gap: 4px;
  display: flex;
  flex-direction: column;
  background-color: $gray-50;
  color: $purple-dark;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.4s ease;
  border: 2px solid transparent;

  svg {
    width: 32px;
    height: 32px;
    stroke-width: 1.5px;
  }

  span {
    font-size: 12px;
    line-height: 18px;
    font-family: $title__font-family;
    font-weight: bold;
  }

  &:focus {
    outline: 0;
  }

  &:hover, &:focus-visible, &:active {
    border-color: $purple-dark;
  }
}

.help_auth_container {
  width: 100%;
  max-width: 415px;
  margin-top: 40px;
  display: flex;
  gap: 8px;
  justify-content: center;
}
