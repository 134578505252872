@import 'assets/styles/mixins.scss';

.glotio_chat_button {
  position: fixed;
  bottom: 17px;
  right: 20px;
  padding: 18px 16px;
  background-color: $white;
  border-radius: 8px;
  box-sizing: border-box;
  transition: all 0.4s ease;
  font-family: $title__font-family;
  border: 0;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 4px 5px 0 rgba(0,11,20,0.06), 0 1px 10px 0 rgba(0,11,20,0.04), 0 2px 4px -1px rgba(0,11,20,0.14) !important;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  color: $black;
  width: 100%;
  max-width: 300px;
  z-index: 1300;

  &:focus {
    outline: 0;
  }

  svg {
    width: 20px;
    height: 20px;
    color: $purple-dark;
  }

  .chevron_icon {
    margin-left: auto;
  }

  .circle {
    position: absolute;
    top: -10px;
    left: 5px;
    width: 20px;
    height: 20px;
    background-color:$danger;
    color: $white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.disabled {
    opacity: .5;
    pointer-events: none;
  }
}
