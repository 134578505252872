@import 'assets/styles/mixins.scss';

.modal_window_content {
  color: $black;
  font-size: rem(15);

  h3, h1 {
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .modal_accept {
    width: 100%;
    margin-top: 30px;
    div {
      width: 150px;
      margin: auto;
    }
  }

  .switch {
    text-align: right;
    margin-top: 15px;
    margin-bottom: 20px;
  }
}