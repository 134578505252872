@import 'assets/styles/mixins.scss';

.field_message_wrapper {
  .field_message {
    display: flex;
    gap: 8px;
    color: $red;
    margin-top: 12px;
    .message {
      font-size: 14px;
      line-height: 16px;
      font-style: italic;
    }
    .icon {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
