@import 'assets/styles/mixins.scss';

.resume_container {
  display: flex;
  gap: 48px;
  margin-top: 32px;
}

.resume_container h1 {
  font-size: 18px;
  font-weight: bold;
  font-family: $title__font-family;
  line-height: 25px;
}

.resume_container p {
  font-size: 16px;
  line-height: 19px;
  font-family: $base__font-family;
  color: $purple;
}

.select_container {
  margin-top: 40px;
}

.select_container h1 {
  font-size: 16px;
  font-weight: bold;
  color: $gray-700;
  font-family: $title__font-family;
  line-height: 19px;
}

.select_input {
  margin-top: 12px;
  width: 50%;
}

.input_alert {
  margin-top: 16px;
}

.footer {
  margin-top: 40px;
}

.card_language_wrapper {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-top: 12px;
}

.not_available {
  font-style: italic;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #9e9e9e !important;
}

.language_card {
  height: 100%;
}

.popover_link {
  display: inline;
  cursor: pointer;
  text-decoration: none;
  color: $purple-dark !important;
  @include transition-color();
  &:hover,
  &:active,
  &:focus-visible {
    color: $purple !important;
    text-decoration: none;
  }
}

.wrapper_btn {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.help_component {
  margin-top: 35px;
}

.modal_button {
  flex: 1;
}

.field_text {
  svg {
    vertical-align: bottom;
    margin-left: 8px;
  }
}
