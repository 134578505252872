.login_wrapper {
  width: 100%;
  max-width: 520px;
  margin: 0 auto;
  button {
      width: 100%;
  }
  .login_logo {
    width: 90px;
    margin: 0 auto 40px auto;
    img {
      max-width: 100%;
    }
  } 
}