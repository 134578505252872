// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN LAYOUT */

.ant-layout {
  min-height: 100%;
  background: transparent;
  flex: 1;
}
.ant-layout.ant-layout-has-sider > .ant-layout {
  overflow-x: visible;
}

.ant-layout-header {
  padding: 0;
  height: 80px;
}

.ant-layout-footer {
  padding: 0;
  background: transparent;
}

.ant-collapse {
  background: transparent;
}

.ant-layout-sider {
  transition: all 0.2s;
}
