.ant-progress-bg {
  height: 14px !important;
}

.ant-progress-text {
  display: none;
}

.ant-progress-outer {
  padding: 0 !important;
}

.ant-layout {
  min-height: 100% !important;
}
