@import 'assets/styles/mixins.scss';

.submenu_tabs {
  display: flex;
  margin-top: 40px;
  gap: 8px;
  padding: 0 80px;

  .tabs {
    padding: 15px;
    font-size: 16px;
    line-height: 19px;
    border-radius: 8px;
    font-weight: $semibold__font-weight;
    color: $purple-dark;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    &_selected {
      @extend .tabs;
      background-color: $purple-dark;
      color: $white;
    }

    &_no_selected {
      @extend .tabs;
      &:hover, &:focus-visible {
        background-color: rgba($purple-dark, .05);
      }
    }
  }

  @media screen and (max-width: $md-max-width) {
    padding: 0 50px;
  }

  @media screen and (max-width: $xs-max-width) {
    padding: 0 25px;
  }
}
