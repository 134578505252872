@import 'assets/styles/mixins.scss';

.faq_modal {
  width: 900px !important;
}

.linkFAQ {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0;
  font-size: 14px;
  line-height: 16px;
  background-color: transparent !important;
  font-style: italic;
  font-weight: normal !important;

  svg {
    width: 16px;
    height: 16px;
  }
}

.error_message {
  font-size: 1.5rem;

  h2 {
    margin-bottom: 16px;
  }
}
