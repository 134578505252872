@import 'assets/styles/mixins.scss';

.wrapper_actions {
  display: flex;
  gap: 16px;
  margin: 0 auto;
  width: 100%;
  flex-wrap: wrap;
}

.wrapper_title, .wrapper_actions  {
  padding: 16px;
}

.title {
  font-size: 24px;
  line-height: 30px;
  color: $black-dark;
  text-align: center;
  font-family: $title__font-family;
  font-weight: bold;
}
.description {
  font-size: 16px;
  line-height: 22px;
  color: $purple;
  text-align: center;
  font-family: $base__font-family;
}

.loading_container {
  text-align: center;
  padding: 26px;
}

.modal_button {
  max-width: 256px;
  margin: 0 auto;
  flex: 1;
}