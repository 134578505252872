@import 'assets/styles/mixins.scss';

.container_btn {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  width: 100%;
  .button {
    max-width: 220px;
    flex: 1;
  }
}

.processing_container {
  margin-bottom: 40px;
  background-color: $gray-50;
}
