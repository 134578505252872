@import 'assets/styles/mixins.scss';

.container {
  padding: 50px 80px;

  @media screen and (max-width: $md-max-width) {
    padding: 50px;
  }

  @media screen and (max-width: $xs-max-width) {
    padding: 25px;
  }
}
