.welcome_section {
  
  display: flex;
  flex-direction: column;
  &.full {
    overflow: auto;
    min-height: 100%;
    height: 0;
  }
  .welcome_header_wrapper {
    flex-shrink: 0;
  }
  .welcome_main {
    flex: 1;
  }
  .welcome_footer{
    flex-shrink: 0;
  }
}