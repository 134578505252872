@import 'assets/styles/mixins.scss';

.summary_container {
  padding: 50px 80px;
  width: 100%;
   @media screen and (max-width: $md-max-width) {
     padding: 50px;
   }

   @media screen and (max-width: $xs-max-width) {
     padding: 25px;
   }
 }

.summary_container_in_first_session {
  padding: 50px 160px;
  width: 100%;
  @media screen and (max-width: $md-max-width) {
    padding: 50px;
  }

  @media screen and (max-width: $xs-max-width) {
    padding: 25px;
  }
}

.summary_children {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.errorMessage {
  margin: 16px 0;
}

.sticky_summary {
  @media screen and (min-width: $xl-max-width) {
    position: sticky;
    top: 0;
  }
}