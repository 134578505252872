@import 'assets/styles/mixins.scss';

.assistantContainer {
  display: flex;
}

.stepContentBlock {
  width: 100%;
}

.center {
  text-align: center;
}

.spin {
  padding-top: 2rem;
  color: $spinner-color;
}
