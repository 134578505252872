@import 'assets/styles/mixins.scss';

.footer {
  width: 100%;
  position: relative;
  background: $white;
  color: $text-darken-more;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding-left: 0;
  padding-right: 0;
  padding-top: 20px;
  padding-bottom: 20px;

  @media screen and (max-width: 991px) {
    padding-left: 50px;

    &:before {
      left: 50px;
    }
  }

  @media screen and (max-width: $xs-max-width) {
    padding-left: 25px;

    &:before {
      left: 25px;
    }
  }

  .footer_container {
    margin-bottom: 0;
    .links {
      
      gap: 16px;
      align-items: center;
      display: flex;
      
    }

    .links_target {
      font-size: 14px;
      color: $gray-500;
      display: flex;
      &:hover, &:focus, &:active {
        color: $primary;
      }
    }
  }

}

:global(.hasFixedBar) {
  margin-bottom: $height_subscriptions_actions;
}
