@import 'assets/styles/mixins.scss';

.wrapper_actions {
  padding: 16px;
  display: flex;
  gap: 16px;
  margin: 0 auto;
  width: 100%;
  
}
.title {
  font-size: 24px;
  line-height: 30px;
  color: $black-dark;
  text-align: center;
  font-family: $title__font-family;
  font-weight: bold;
}
.description {
  font-size: 16px;
  line-height: 22px;
  color: $purple;
  text-align: center;
  font-family: $base__font-family;
}

.wrapper_title {
  padding: 16px;
  
}

.subinfo {
  margin-top: 26px;
  font-family: $base__font-family;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  a{
    color:$purple-dark
  }
}