@import 'assets/styles/mixins.scss';

.layout_wrapper {
  padding: 40px 80px;
  justify-content: center;
  display: flex;

  @media screen and (max-width: $md-max-width) {
    padding: 50px;
  }

  @media screen and (max-width: $xs-max-width) {
    padding: 25px;
  }

  .layout_container {
    max-width: $xl-min-width;
    width: 100%;
  }
}
