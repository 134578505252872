@import 'assets/styles/mixins.scss';

.language_info_wrapper
{
    border: 1px solid $gray-100;
    border-radius: 8px;
    padding: 32px 36px 32px 36px;
    max-width: $xl-min-width;
    width: 100%;
    &.fluid {
        max-width: 100%;
    }
    .title {
        font-size: 24px;
        line-height: 30px;
        font-family: $title__font-family;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .description {
        font-size: 16px;
        line-height: 19px;
        font-family: $base__font-family;
        color: $purple;
    }

    .info_content {
        margin-bottom: 32px;
    }
}
