@import 'assets/styles/mixins.scss';

.grid_layout {
  padding-top: 30px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px 22px;
  max-width: 70rem;
  .form_item {
    margin: 0;
    input, div:last-child {
      border-radius: 8px !important;
    }
    :global(.ant-select-selection__placeholder)  {
      position: absolute;
      overflow: visible;
    }
    :global(.ant-form-item-label) {
      margin-left: 16px;
      line-height: 14px;
      margin-bottom: 4px;
      label {
        color: $gray-700;
        font-family: $base__font-family;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 14px;
        font-weight: 400;
      }
    }
    &.hasError {
      :global(.ant-form-item-label) { 
        label {
          color: $red;
         }
      }
      :global(.ant-form-item-children-icon) {
        color: $red;
      }
    }
    :global(.has-error .ant-input) {
      border-color: $red;
    }
  }
}

.fluid_col {
  grid-column: 1 / span 12;
}
.col_1_9 {
  grid-column: 1 / span 8;
}
.col_3_12 {
  grid-column: 9 / span 4;
}

.fieldMessage > div {
  margin-top: 0!important;
}