@import '../../mixins.scss';

.ant-form label {
  font-size: 1.4rem;
  font-weight: 500;

  &::after {
    content: '';
  }
}
