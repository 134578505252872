@import 'assets/styles/mixins.scss';

.header__container {

  padding-bottom: 22px;
  margin: 0 auto;
  h1 {
    font-family: $title__font-family;
    font-size: 2.4rem;
    font-weight: bold;
    text-align: center;
  }

  p {
    font-family: $base__font-family;
    font-weight: 400;
    font-size: 1.6rem;
    margin-top: 15px;
    margin-bottom: 5px;
    text-align: center;
  }
}
