@import 'assets/styles/mixins.scss';
.resume_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
}

.resume_container h1 {
  font-size: 18px;
  font-weight: bold;
  font-family: $title__font-family;
  line-height: 25px;

  &.default_language {
    text-transform: capitalize;
  }
}

.resume_container p {
  font-size: 16px;
  line-height: 19px;
  font-family: $base__font-family;
  color: $purple;
}

.lang_default_wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  p {
    margin: 0;
  }
}
