@import 'assets/styles/mixins.scss';

.carousel_slide {
  display: table;
  justify-content: center;
}

.carousel_slide_content {
  display: table-cell;
  vertical-align: middle;
  padding-right: 32px;
}

.title_category {
  font-size: 14px;
  color: $purple-dark;
  font-family: $base__font-family;
  line-height: 16px;

  .subtitle:not(a) {
    color: $purple;
  }
}

.title {
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
  font-family: $title__font-family;
  margin-bottom: 16px;
}
