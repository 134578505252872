@import 'assets/styles/mixins.scss';

.wrapper_actions {
    padding: 16px;
    display: flex;
    gap: 16px;
    margin: 0 auto;
    width: 100%;
    flex-wrap: wrap;
}
.title {
  font-size: 24px;
  line-height: 30px;
  color: $black-dark;
  text-align: center;
  font-family: $title__font-family;
  font-weight: bold;
}
.description {
  font-size: 16px;
  line-height: 22px;
  color: $purple;
  text-align: center;
  font-family: $base__font-family;
}

.wrapper_title,.wrapper_image {
  padding: 16px;
}
.wrapper_image{
  overflow: hidden;
  border-radius: 8px;
}
