@import 'assets/styles/mixins.scss';

.default_error_wrapper {
  margin-top: 40px;
  display: flex;
  justify-content: center;

  .children_wrapper {
    margin-top: 32px;
  }

  .default_error {
    max-width: 630px;
    width: 100%;
    text-align: center;
  }

  .icon {
    width: 64px;
    height: 64px;
    color: $purple-dark;
    stroke-width: 1px;
  }

  h2 {
    font-size: 24px;
    line-height: 30px;
    font-family: $title__font-family;
    font-weight: $semibold__font-weight;
    margin-top: 12px;
  }

  p {
    margin: 12px 0 0;
    font-size: 18px;
    line-height: 24px;
    color: $purple;
  }

  .actions_buttons {
    display: flex;
    padding: 0 35px;
    gap: 16px;
    margin-top: 32px;
    justify-content: center;

    .buttons {
      flex: 1;
      max-width: 272px;
    }
  }

  .footer_wrapper {
    margin-top: 64px;

    .title_faq {
      color: $text-span;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
    }

    .faqs_content {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      svg {
        width: 20px;
        height: 20px;
      }

      .faq_container {
        background-color: $gray-50 !important;
        padding: 10px 16px;
        font-size: 16px !important;
        border: 1px solid transparent;

        &:hover {
          color: $purple-dark;
          border-color: $purple-dark;
        }
      }

      .link_icon {
        margin-left: 16px;
      }
    }
  }

  .help_error {
    margin: 32px auto 0;
  }
}
