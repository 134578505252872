@import 'assets/styles/mixins.scss';

.login {
  background-size: cover;
  background-color: $gray-lighten-more;
  background-position: center center;
}

.title {
  margin: 0 auto;
  max-width: rem(740);
  text-align: center;
  padding-left: rem(20);
  padding-right: rem(20);
  h1 {
    font-weight: 900;
    margin-bottom: rem(30);
  }
}

.block {
  padding: rem(40) rem(40) rem(80);
  width: 100%;
  align-self: center;
  position: relative;
  z-index: 2;

  @media (max-width: $md-max-width) {
    padding: rem(20);
  }
}

.inner {
  min-width: rem(300);
  max-width: rem(500);
  margin: 0 auto;
  padding: rem(50) rem(40) rem(20);
  border-radius: 10px;
  overflow: hidden;
  background-color: $white;
  position: relative;

  @media (max-width: $md-max-width) {
    padding: rem(30) rem(30) rem(10);
  }
}

.form {
  position: relative;
  z-index: 2;
}

.wrapper_form {
  width: 100%;
  border: 1px solid $gray-100;
  border-radius: 8px;
  max-width: 520px;
  padding: 32px;

  :global(.ant-divider-inner-text) {
    color: $gray-300;
    font-weight: bold;
    font-family: $title__font-family;
  }

  form {
    max-width: none;
    .form_item {
      margin-bottom: 0;
      .form_label {
        color: $gray-700;
        font-family: $base__font-family;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 14px;
        margin: 0 0 4px 16px;
      }
      :global(.ant-input) {
        border: 1px solid $gray-300;
        border-radius: 8px;
        &:hover{
          border: 1px solid $gray-300;
        }
      }
      :global(.has-error .ant-input) {
        border: 1px solid $red;
      }
      :global(.ant-form-explain) {
        color: $red;
      }
      :global(.has-error) {
        .form_label {
          color: $red;
        }
      }

      &.mb_24 {
        margin-bottom: 24px;
      }

      &.forgot_password {
        text-align: center;
        margin-bottom: 0;
        font-size: 14px;
      }

    }
  }
  .button_form {
    width: 100%;
    max-width: 258px;
    margin: 0 auto;
    button, a {
      font-size: 1.8rem;
      max-width: none;
    }
  }
}

.form_separator_xsmall {
  padding-bottom: 10px;
}

.form_separator_small {
  padding-bottom: 22px;
}
.form_separator_medium {
  padding-bottom: 32px;
}

.has_account {
  color: $purple;
  font-family: $base__font-family;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.success_email_sent {
  color: $purple;
  font-family: $base__font-family;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}

.send_email {
  margin-top: 32px;
  color: $purple;
  font-family: $base__font-family;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.send_email_link {
  color: $purple-dark !important;
  text-decoration: none !important;
}

.loading_text {
  padding-top: 35px;
  font-size: 1.4rem;
}

.showPassword {
  position: absolute;
  cursor: pointer;
  top: -60px;
  right: 0;
  display: flex;
  align-items: center;

  i {
    font-size: 1.6rem;
    margin-right: 5px;
  }

  span {
    font-size: 1.4rem;
    user-select: none;
  }
}

.policy_check {
  display: flex;
  align-items: flex-start;
  margin-bottom: 35px;

  span:first-child {
    margin-top: 4px !important;
  }

  span:last-child {
    display: inline-block;
    line-height: 1.5;
  }
}

.promoActivated {
  padding-left: 60px !important;
}

.sign_with {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  p {
    color: $purple;
    font-size: 14px;
    font-weight: $semibold__font-weight;
    margin-bottom: 0;
  }

  &_buttons {
    display: flex;
    justify-content: center;
    gap: 8px;
  }
}
