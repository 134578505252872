@import 'assets/styles/mixins.scss';

.link {
  text-decoration: none !important;
}

@mixin button_default_color($color-default, $color-secondary, $opacity: .8, $hover: $color-default) {
  background-color: $color-default;
  color: $color-secondary;

  .spin, :global(.ant-spin-dot) {
    color: $color-secondary;
  }

  &:hover, &:active, &:focus-visible {
    background-color: rgba($hover, $opacity);
    color: $color-secondary;
  }
}

@mixin button_outlined_color($color-default, $opacity: .05, $hover: null) {
  background-color: transparent;
  box-shadow: inset 0 0 0 1px $color-default !important;
  color: $color-default;

  .spin, :global(.ant-spin-dot) {
    color: $color-default;
  }

  &:hover, &:active, &:focus-visible {
    @if ($hover != null) {
      box-shadow: inset 0 0 0 1px $hover !important;
      color: $hover;

      .spin, :global(.ant-spin-dot) {
        color: $hover;
      }
    }
    @else {
      background-color: rgba($color-default, $opacity);
      color: $color-default;
    }
  }
}

@mixin button_link_color($color-default, $opacity: .05, $hover: null) {
  background-color: transparent;
  color: $color-default;

  .spin, :global(.ant-spin-dot) {
    color: $color-default;
  }

  &:hover, &:active, &:focus-visible {
    @if ($hover != null) {
      color: $hover;

      .spin, :global(.ant-spin-dot) {
        color: $hover;
      }
    }
    @else {
      background-color: rgba($color-default, $opacity);
      color: $color-default;
    }
  }
}

.button_small {
  font-size: 1.4rem;
  padding: .35em;
  line-height: 1.6rem;
  font-weight: $semibold__font-weight;

  .spin, :global(.ant-spin-dot) {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}

.button_medium {
  font-size: 1.6rem;
  padding: .69em;
  line-height: 1.9rem;
  font-weight: $semibold__font-weight;

  .spin, :global(.ant-spin-dot) {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
}

.button_large {
  font-size: 1.8rem;
  padding: 1em;
  line-height: 2.1rem;
  font-weight: bold;

  .spin, :global(.ant-spin-dot) {
    font-size: 1.8rem;
    line-height: 2.1rem;
  }
}

.button_container {
  border-radius: 8px;
  box-sizing: border-box;
  transition: all 0.4s ease;
  font-family: $base__font-family;
  border: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  &:focus {
    outline: 0;
  }

  svg {
    width: 1em;
    height: 1em;
  }
}

.button_default {
  @extend .button_container;
  @include button_default_color($purple-dark, $white, 1, $purple);

  &.warning {
    @include button_default_color($warning, $white);
  }

  &.success {
    @include button_default_color($success, $white);
  }

  &.error {
    @include button_default_color($red, $white);
  }

  &.secondary {
    @include button_default_color($black, $white);
  }

  &.light {
    @include button_default_color($white, $black, .6);
  }

  &.disabled {
    background-color: $gray-500;
    pointer-events: none;
  }
}

.button_outlined {
  @extend .button_container;
  @include  button_outlined_color($purple-dark, 1, $purple);

  &.warning {
    @include button_outlined_color($warning);
  }

  &.success {
    @include button_outlined_color($success);
  }

  &.error {
    @include button_outlined_color($red);
  }

  &.secondary {
    @include button_outlined_color($black);
  }

  &.light {
    @include button_outlined_color($white, .3);
  }

  &.disabled {
    color: $gray-500;
    box-shadow: inset 0 0 0 1px $gray-500 !important;
    pointer-events: none;
  }
}

.button_link {
  @extend .button_container;
  @include button_link_color($purple-dark, 1, $purple);

  &.warning {
    @include button_link_color($warning);
  }

  &.success {
    @include button_link_color($success);
  }

  &.error {
    @include button_link_color($red);
  }

  &.secondary {
    @include button_link_color($black);
  }

  &.light {
    @include button_link_color($white, .3);
  }

  &.disabled {
    color: $gray-500;
    pointer-events: none;
  }
}

.button_fluid {
  width: 100%;
}
