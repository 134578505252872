@import 'assets/styles/mixins.scss';

.layout {
  min-height: 100%;
  height: 0;
  overflow: auto;

  .promotion_banner {
    margin: 0;
    height: 50px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    text-align: center;
    background-color: $danger;
    transition: all 500ms ease-out;

    &.hidden {
      opacity: 0;
      height: 0;
    }

    &_text {
      margin: 0;
      font-size: 1.4rem;
      font-weight: normal;

      b {
        font-weight: 600;
      }

      a {
        text-decoration: underline;
        color: $white;
        &:hover {
         color: $primary;
        }
      }
    }
  }
}

.authRow {
  display: flex;
  min-height: 100%;
}

.leftColumn {
  flex: 1;
  padding: 30px 30px 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .img__wrapper {
    width: 100%;
    max-width: 400px;
    margin-bottom: 40px;

    picture {
      width: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 90px;
        height: auto;
        object-position: center;
      }

      @media screen and (max-width: 480px) {
        margin-top: 25px;
        justify-content: center;
      }
    }
  }

  header {
    width: 100%;
    max-width: 400px;
  }

  form {
    width: 100%;
    max-width: 400px;

    label strong {
      font-weight: 600;
    }
  }

  button {
    width: 100%;
    max-width: 400px;
    font-size: 1.4rem;

    a {
      text-decoration: none;
    }
  }

  .footer {
    margin-top: 67px;
    a {
      text-decoration: none !important;
    }
  }

  @media screen and (max-height: 800px) {
    padding: 15px 30px 0 30px;
  }

  @media screen and (min-height: 1024px) {
    padding: 0 30px;
    justify-content: center;
  }
}

.lang_selector {
  position: absolute;
  top: 56px;
  right: 42px;
  border: 2px solid $gray;
  padding: 8px 16px;
  border-radius: 3px;
  background-color: $white;
}

.cookie_consent {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 100000;
}

.cookie_consent_container {
  width: 35%;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  color: $black;
  font-size: rem(15);
  background-color: transparent;
}

.cookie_consent_inside_container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: rem(20);
  color: $black;
  font-size: rem(15);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0,0,0,0.5) 0 0 30px -10px,
}

%cookie_consent_buttons {
  color: $white;
  background-color: rgb(0, 0, 0);
  border: 0;
  margin-right: rem(15);
  text-transform: uppercase;
  padding: rem(10);
  font-size: rem(14);
}

.cookie_consent_accept_button {
  @extend %cookie_consent_buttons;
}

.cookie_consent_accept_delcine {
  @extend %cookie_consent_buttons;
}


.cookie_consent_links {
  margin-bottom: rem(20);
  a {
    text-decoration: underline;
  }
  a:hover {
    color: $primary;
  }
}

.alert_link {
  margin-left: 12px;
  color: $white;
  text-decoration: underline;
  &:hover,
  &:active,
  &:focus {
    color: rgba($white, 0.8);
  }
}

