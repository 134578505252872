// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN SELECT */

.ant-select {
  font-family: 'Work Sans', sans-serif;
  font-size: 1.3rem;

  .ant-select-selection--single {
    height: 50px;
    border-radius: 8px;
    border-width: 1.5px !important;
  }

  .ant-select-selection__rendered {
    margin-left: 18px;
    line-height: 47px;
  }

  .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    margin-right: 20px;
  }

  &:not(.ant-select-disabled) .ant-select-selection:hover {
    border-color: $primary;
  }

  .ant-select-arrow {
    font-size: 11px;
    transform: scaleY(0.8);
  }
}

.ant-select-dropdown {
  border: 1px solid #e9e9e9;
  animation-duration: 0s !important;
}

.ant-select-auto-complete {
  ol,
  ul,
  dl {
    margin: 0;
  }
}
