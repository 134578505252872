@import 'assets/styles/mixins.scss';

@mixin alert_color($color-default, $color-secondary) {
  color: $color-default;
  background-color: $color-secondary;
}

.alert_container {
  padding: 16px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  font-style: italic;
  gap: 8px;

  svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }
  &.alert_error {
    @include alert_color($red, rgba($red, $alpha_glotio_alerts));
  }

  &.alert_info_danger {
    @include alert_color($danger-dark, $gray-50);
  }

  &.alert_success {
    @include alert_color($green, rgba($green, $alpha_glotio_alerts));
  }
}
