body {
  // overriding (making high level priority) antd styles by wrapping this styles body tag
  @import 'import/alert';
  @import 'import/backtop';
  @import 'import/badge';
  @import 'import/button';
  @import 'import/calendar';
  @import 'import/cascader';
  @import 'import/dropdown';
  @import 'import/form';
  @import 'import/input';
  @import 'import/label';
  @import 'import/menu';
  @import 'import/modal';
  @import 'import/popover';
  @import 'import/radio-group';
  @import 'import/select';
  @import 'import/slider';
  @import 'import/spin';
  @import 'import/switch';
  @import 'import/layout';
  @import 'import/notification';
  @import 'import/progress';
  @import 'import/table';
  @import 'import/tag';
}
