@import 'assets/styles/mixins.scss';

.process_layout > div {
  max-width: 850px !important;
}

.warning_message {
  display: flex;
  justify-content: center;
  margin: 16px 0 40px;

  .warning_container {
    width: 100%;
  }
}

.carousel_wrapper {
  background-color: $gray-50;
}
