@import 'assets/styles/mixins.scss';


.innerWrapper {
  box-shadow: 0 -1px 0 0 $gray-300;
  padding: 16px 32px;
  background: $white;
}
.basicTemplate {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 16px;
 
  [data="button"] {
    min-width: 256px;
  }
  
  @media screen and (min-width: $sm-min-width) {
    flex-direction: row;
  }
}

.gridTemplate {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  place-items: center;
  [data="button"] {
    min-width: 256px;
  }
  [data="icon"] {
    justify-self: baseline;
  }
  @media screen and (min-width: $sm-min-width) {
    grid-template-columns: repeat(3, 1fr);
  }
}