@import 'assets/styles/mixins.scss';

// Card stored
.card_container {
  display: flex;
  align-items: center;
  flex: 1;

  & > span {
    font-size: 18px;
    line-height: 21px;
    color: $purple;
  }

  .card_info {
    margin-left: 8px;
  }

  .card_date {
    margin-left: auto;
  }
}
