@import 'assets/styles/mixins.scss';

.column_description {
    font-size: 14px;
    line-height: 16px;
    color:$text-span;
    font-family: $base__font-family;
    font-style: italic;
    margin: 0;
}

.column_title {
  color: $text-span;
  font-size: 16px;
  margin-bottom: 4px;
  line-height: 18px;
}

.column_title_highlight {
  color: $purple-dark;
  font-size: 18px;
  line-height: 21px;
}

.language_history_container {
    margin-bottom: 32px;
    margin-top: 12px;
}

.history_table_subtitle {
    font-size: 16px;
    line-height: 19px;
    color:$gray-700;
    font-family: $base__font-family;
    font-weight: bold;
}
.language_info_message {
    margin-bottom: 32px;
    background-color: #FAFAFA;
    border-radius: 8px;
    padding: 18px 16px;
    color: $green;
    display: flex;
    gap: 16px;
    font-family: $base__font-family;
    .check_icon{
        width: 16px;
        height: 16px;
    }
    .message_text {
        font-size: 14px;
        line-height: 16px;
        font-style: italic;
    }
}

.children_container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.update_summary {
  padding: 24px;
  background-color: $gray-100;
  border-radius: 8px;
  .summary_wrapper_title {
    padding-bottom: 16px;
    .summary_title {
      font-size: 18px;
      font-weight: bold;
      font-family: $title__font-family;
      line-height: 28px;
      margin: 0;
      color: $black-dark;
    }
  }
  .summary_sub_text {
    font-size: 14px;
    line-height: 16px;
    color: $text-span;
    font-family: $base__font-family;
    font-style: italic;
  }

  .summary_subtitle {
    font-size: 16px;
    font-weight: 600;
    font-family: $base__font-family;
    line-height: 19px;
    margin: 0;
    color: $black-dark;
  }

  .summary_text {
    color: $purple;
    font-family: $base__font-family;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    &.summary_highlight {
      color: $purple-dark
    }
  }
  .summary_content {
    display: flex;
    flex-direction: column;
    grid-gap: 32px;
    gap: 32px;
    .summary_section {
      padding-top: 12px;
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 13px;
      align-items: center;
      &.small_gap {
        grid-gap: 4px;
      }
      &.no_padding {
        padding: 0;
      }
      & > :nth-child(2n) {
        text-align: end;
      }
    }
    .summary_progress {
      padding-top: 12px;
    }
  }
  
  .summary_separator {
    padding-bottom: 32px;
  }
}

.last_analysis {
  display: flex;
  gap: 12px;
  align-items: center;

  &_warning {
    @extend .last_analysis;
    p {
      color: $warning;
    }
  }
}

.start_analysis_btn {
  max-width: 254px;
}

.info_wrapper_tabs {
  padding-bottom: 40px;
}

.update_info_wrapper_tabs {
  padding: 40px 0 0 40px;
  @media (min-width: $md-max-width) {
    padding: 40px 0 0 80px;
    
  }
  
}
.update_info_with_container_tabs {
  padding: 0 0 40px 0;
}

.info_wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}