@import 'assets/styles/mixins.scss';

.StripeElement {
  @include transition-slow;
  padding: 15px 10px;
  border: 1.5px solid #e4e9f0;
}

.StripeElement--focus,
.StripeElement:hover {
  border: 1.5px solid $primary;
}

.StripeElement--invalid {
  border: 1.5px solid $danger;
}

.StripeError {
  color: $danger;
  font-size: 1.2rem;
  margin: 0;
  // animation: showMessage 800ms ease-out;
}

@keyframes showMessage {
  from {
    opacity: 0;
    height: 0;
  }

  to {
    opacity: 1;
    height: 20px;
  }
}
