@import 'assets/styles/mixins.scss';

.popover_container {
  border-radius: 8px !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;

  :global(.ant-popover-arrow) {
    display: none;
  }

  :global(.ant-popover-inner-content) {
    border-radius: 8px;
    box-shadow: $tooltip_shadow
  }
}

.popover_content {
  padding: 16px;
  max-width: 300px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 16px;
  &:not(a) {
    color: $purple;
  }
}

.info_icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  &_active {
    @extend .info_icon;
    color: $purple;
  }
}

.circle_icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: $purple-dark;
}

.circle_icon_container {
  display: flex;
  align-items: center;
  flex-grow: 4;
  margin-left: 16px;
}
