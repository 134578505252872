@import 'assets/styles/mixins.scss';

.question_container {
  margin-top: 32px;
}

.question_block {
  display: flex;
  padding: 18px 16px;
  background: $gray-50;
  border-radius: 8px;
  margin-top: 8px;
  @include transition-middle();

  &:hover {
    box-shadow: 0 0 0 1px $purple-dark;

    & label:after {
      border-color: $purple-dark;
    }
  }
}

.active_question {
  border: 1px solid $purple-dark;
}

.question_input {
  margin-left: 32px;
}

.question_title {
  margin-left: 16px;
  font-family: $base__font-family;
  font-size: 18px;
  color: $black-dark;
  line-height: 21px;

  & p{
    margin-bottom: 0px;
    display: inline;
  }
}

.active_question_title {
  color: $purple-dark;
}

.question_subtitle {
  font-family: $base__font-family;
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  color: $purple-dark;
  margin-left: 8px;
  margin-bottom: 0px;
  display: inline;
}

.question_icon {
  margin-left: 8px;
  color: $gray-700;
  svg {
    width: 16px;
    height: 16px;
    margin-top: auto;
  }
}

.label_question {
  width: 100%;
  cursor: pointer;
}

.alert_wrapper {
  margin-top: 12px;
}
