@import 'assets/styles/mixins.scss';

.menu_container {
  min-width: 200px;
  width: 250px !important;
  padding-top: 78px;
  background: #f8f8f8 !important;
  border: none;

  li {
    margin: 0 !important;
    // height: 50px !important;
    display: flex !important;
    align-items: center;
  }

  #submenu_selected {
    background-color: #fff !important;

    a {
      color: $primary !important;
    }
  }
}
