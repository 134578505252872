@import 'assets/styles/mixins.scss';

.dropdown {
  padding-right: 15px;
  position: relative;
  cursor: pointer;
}

.avatar {
  background-color: $purple-dark;
  font-size: 1.5rem;
  letter-spacing: -1.5px;
  &.isVisible {
    background-color: $purple;
  }
}

.menuIcon {
  margin-right: rem(5);
}

.custom__button {
  appearance: none;
  border: none;
  background: none;
  width: 100%;
  padding: 1rem 1.66rem;
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  &.close_session_btn{
    color: $red;
  }
  .icon_text {
    
    font-family: $base__font-family;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
  }
}
.menu_item {
  padding: 0!important;
}