@import "assets/styles/mixins.scss";

.articlesFAQ {
  padding: 12px;

  p {
    font-size: 16px;
    line-height: 19px;
    color: $purple;
  }

  header {
    margin-bottom: 32px;
    h1 {
      font-size: 32px;
      line-height: 36px;
      font-family: $title__font-family;
      font-weight: $semibold__font-weight;
      margin-bottom: 8px;
    }
  }
  .articlesFAQ_body {

    h2 {
      font-size: 24px;
      line-height: 30px;
      font-family: $title__font-family;
      font-weight: $semibold__font-weight;
      margin: 0 0 15px;
    }

    h3 {
      font-size: 18px;
      margin: 0 0 15px;
      font-weight: 700;
      line-height: 24px;
    }

    img {
      max-width: 100%;
      height: auto;
      margin: 12px auto;
      display: block;
    }

    li {
      font-size: 16px;
      line-height: 19px;
      & + li {
        margin-top: 8px;
      }
    }

    table {
      width: 100%;
      margin: 36px 0;
      border-collapse: separate;
      border-spacing: 0;

      td {
        padding: 8px;
        text-align: left;
        font-size: 16px;
        line-height: 19px;
        color: $purple;
        border: 1px solid #cccccc;
        border-bottom-width: 0;
        border-left-width: 0;
      }

      td:first-child {
        border-left-width: 1px;
      }

      tr:first-child {
        td:first-child {
          border-top-left-radius: 8px;
        }

        td:last-child {
          border-top-right-radius: 8px;
        }
      }

      tr:last-child {
        td {
          border-bottom-width: 1px;
        }

        td:first-child {
          border-bottom-left-radius: 8px;
        }

        td:last-child {
          border-bottom-right-radius: 8px;
        }
      }
    }

    .button {
      cursor: pointer;
      padding: 11px;
      max-width: 252px;
      width: 100%;
      background-color: $purple-dark;
      color: $white;
      font-size: 16px;
      line-height: 19px;
      border-radius: 8px;
      font-weight: $semibold__font-weight;
      transition: all 0.4s ease;

      &:hover, &:active, &:focus-visible {
        background-color: $purple;
      }
    }

    .video-iframe {
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding: 0 0 56.25%;

      iframe {
        position: absolute;
        margin: 0;
        top: 0;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        border: 0;
      }
    }
  }
}
