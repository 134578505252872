@import 'assets/styles/mixins.scss';

.shopify_link {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border: 1px solid $shopify-green;
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.4s ease;

  img {
    width: 24px;
    height: 24px;
  }

  span {
    font-size: 18px;
    font-weight: $semibold__font-weight;
    font-family: $title__font-family;
    color: $black;
    line-height: 21px;
  }

  &:hover, &:active, &:focus-visible {
    background-color: $gray-100;
    border-color: $gray-100;
  }
}
