@import 'assets/styles/mixins.scss';

.info_danger_container {
  width: 100%;
  display: flex;
  gap: 8px;
  font-size: 14px;
  line-height: 16px;
  color: $danger-dark;
  font-style: italic;
  svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }
}
