@import 'assets/styles/mixins.scss';

.wrapper_tabs {
  margin-top: 0;
  padding: 0;
  display: flex;
  @media (max-width: $sm-max-width) {
    display: none;
  }
}
